import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { withRouter } from 'react-router';

import * as applicantActions from 'frontend/actions/login-applicant';
import Navbar from 'frontend/components/Navbar';
import Footer from 'frontend/components/Footer';
import { Dimmer, Loader } from 'semantic-ui-react';
// permisos por defecto para cualquier ruta
// mustBeSso indica si el usuario tiene que ser del sso
// mustBeLocal indica si el usuario tiene que ser local
const defaultPermissions = {
  mustBeSso: false,
  mustBeLocal: false,
  permittedForAll: false,
};

const InnerLayout = (CurrentPage, permissions) => (props) => {
  const {
    loggedUser,
    isFetching,
    askedForLogin,

    actions: {
      getLoggedUser,
    },
    location: {
      search,
    },
  } = props;

  const {
    mustBeLocal,
    mustBeSso,
    permittedForAll,
    showOffersTitle,
  } = permissions;

  const actualPath = window.location.pathname;
  let validating = true;
  if (!loggedUser && !askedForLogin) getLoggedUser();

  // Al iniciar sesion, verifico si existe el parametro jobOffer para redireccionar
  // Hacia la oferta que estaba mirando (solo como postulante)
  let wait = false;

  if (loggedUser && askedForLogin && search.includes('joboffer')
    && actualPath === '/iniciar-sesion-postulante') {
    const id = Number(search.split('=')[1]);
    if (Number.isInteger(id)) {
      wait = true;
      window.location = `/busquedas/${id}`;
    }
  }

  // reviso permisos si es que ya se hice el fetch del current user
  // a no ser que esta url sea de acceso publico

  if (!permittedForAll && askedForLogin && !wait) {
    if ((mustBeLocal || mustBeSso)) {
      // el usuario no esta logeado y solo se puede acceder a esta ruta si estas logeado
      if ((!loggedUser) || (mustBeLocal && !mustBeSso && loggedUser.sso)
      || (mustBeSso && !mustBeLocal && !loggedUser.sso)) {
        window.location = '/';
      }
      // el usuario esta logeado y no completo el perfil
      if (loggedUser && loggedUser.must_complete_profile && actualPath !== '/completar-perfil') {
        window.location = '/completar-perfil';
      }
      // el usuario esta logeado y solo se puede acceder a esta ruta si no estas logeado
    } else if (!(mustBeLocal || mustBeSso) && loggedUser) {
      window.location = '/';
    }
    validating = false;
  } else if (askedForLogin) {
    validating = false;
  }

  if (validating && (actualPath !== '/')) {
    return (
      <Dimmer active>
        <Loader>Cargando</Loader>
      </Dimmer>
    );
  }

  return (
    <>
      <Navbar loggedUser={loggedUser} isFetching={isFetching} showOffersTitle={showOffersTitle} />
      <div className="layout">
        <CurrentPage currentUser={loggedUser} {...props} />
      </div>
      <Footer />
    </>
  );
};

const mapStateToProps = (state) => ({
  loggedUser: state.loginApplicant.loggedUser,
  askedForLogin: state.loginApplicant.askedForLogin,
});

function mapDispatchToProps(dispatch) {
  return ({
    actions: bindActionCreators({
      ...applicantActions,
    }, dispatch),
  });
}

const Layout = (CurrentPage, permissions = defaultPermissions) => withRouter(connect(
  mapStateToProps,
  mapDispatchToProps,
)(InnerLayout(CurrentPage, permissions)));

export default Layout;
