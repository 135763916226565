import React from 'react';
import { Form } from 'semantic-ui-react';

const RenderCheckbox = (field) => (
  <Form.Field
    width={field.width}
    inline={field.inline}
    required={field.required}
    disabled={field.disabled}
    error={Boolean(field.error)}
  >
    <br />
    <Form.Checkbox
      checked={!!field.input.value}
      name={field.input.name}
      label={field.label}
      toggle={field.toggle}
      required={field.required}
      onChange={(e, { checked }) => field.input.onChange(checked)}
    />
    <div>{field.error && <span style={{ color: 'red' }}>{field.error}</span>}</div>
  </Form.Field>
);

export default RenderCheckbox;
