export const LOGIN_APPLICANT_REQUEST = 'login-applicant-request';
export const LOGIN_APPLICANT_SUCCESS = 'login-applicant-success';
export const LOGIN_APPLICANT_FAILURE = 'login-applicant-failure';

export const GET_LOGGED_USER_REQUEST = 'get-logged-user-request';
export const GET_LOGGED_USER_SUCCESS = 'get-logged-user-success';
export const GET_LOGGED_USER_FAILURE = 'get-logged-user-failure';

export const ON_DISMISS_MESSAGE = 'on-dismiss-message';
export const ON_CAPTCHA_UPDATE = 'on-captcha-update';
