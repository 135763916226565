import React from 'react';
import { Divider, Form, Segment } from 'semantic-ui-react';
import { Field } from 'redux-form';
import LocationForm from 'frontend/containers/Location/Location';
import { RenderInput } from 'frontend/components/Form';

const Address = (props) => {
  const {
    errors,
    onErrors,
  } = props;
  return (
    <Segment raised color="blue">
      <Divider horizontal>Domicilio</Divider>
      <Form.Group>
        <Field
          component={RenderInput}
          error={onErrors(errors['profile.address.street'])}
          name="profile.address.street"
          fluid
          width={5}
          label="Calle"
          placeholder="Calle"
        />
        <Field
          component={RenderInput}
          error={onErrors(errors['profile.address.adjacent_streets'])}
          name="profile.address.adjacent_streets"
          fluid
          width={5}
          label="Entre calles"
          placeholder="Entre calles"
        />
        <Field
          component={RenderInput}
          error={onErrors(errors['profile.address.number'])}
          name="profile.address.number"
          fluid
          width={2}
          label="Número"
          placeholder="Número"
        />
        <Field
          component={RenderInput}
          error={onErrors(errors['profile.address.story'])}
          name="profile.address.story"
          fluid
          width={2}
          label="Piso"
          placeholder="Piso"
        />
        <Field
          component={RenderInput}
          error={onErrors(errors['profile.address.room_number'])}
          name="profile.address.room_number"
          fluid
          width={2}
          label="Departamento"
          placeholder="Departamento"
        />
      </Form.Group>
      <LocationForm
        countryName="profile.address.country"
        countryErrors={errors.country}
        countryLabel="Pais"
        provinceLabel="Provincia"
        provinceName="profile.address.province.id"
        partyLabel="Partido"
        partyName="profile.address.party.id"
        cityLabel="Ciudad"
        cityTextLabel="Ciudad"
        cityName="profile.address.city.id"
        cityTextName="profile.address.city_name"
        cityErrors={errors['profile.address.city']}
        cityTextErrors={errors['profile.address.city_name']}
        required
      />
    </Segment>
  );
};

export default Address;
