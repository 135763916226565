import {
  LOGIN_APPLICANT_FAILURE,
  LOGIN_APPLICANT_SUCCESS,
  LOGIN_APPLICANT_REQUEST,

  GET_LOGGED_USER_REQUEST,
  GET_LOGGED_USER_SUCCESS,
  GET_LOGGED_USER_FAILURE,

  ON_CAPTCHA_UPDATE,
  ON_DISMISS_MESSAGE,
} from 'constants/login-applicant';

import axios from 'helpers/axios';

export const loginApplicant = (values) => (dispatch) => {
  dispatch({ type: LOGIN_APPLICANT_REQUEST });
  return axios({
    url: '/api/v1/sessions/applicants',
    data: values,
    method: 'POST',
  }).then(
    (res) => {
      dispatch({ type: LOGIN_APPLICANT_SUCCESS, payload: { loggedUser: res.data } });
    },
  ).catch((err) => dispatch({
    type: LOGIN_APPLICANT_FAILURE,
    payload: {
      code: err.response.status,
      errors: err.response.data.error,
    },
  }));
};

export const dismissMessage = (message) => (dispatch) => (
  dispatch({
    type: ON_DISMISS_MESSAGE,
    payload: { message },
  })
);

export const captchaValidUpdate = (value) => (dispatch) => (
  dispatch({
    type: ON_CAPTCHA_UPDATE,
    payload: { value },
  })
);

export const getLoggedUser = () => (dispatch) => {
  dispatch({ type: GET_LOGGED_USER_REQUEST });
  return axios({
    url: '/api/v1/sessions/current',
  }).then(
    (res) => {
      dispatch({ type: GET_LOGGED_USER_SUCCESS, payload: { loggedUser: res.data } });
    },
  ).catch(() => dispatch({ type: GET_LOGGED_USER_FAILURE }));
};

export default loginApplicant;
