import React from 'react';
import {
  Grid,
  Segment,
  Divider,
  Form,
  Button,
  Icon,
  Header,
} from 'semantic-ui-react';
import { Field, FieldArray } from 'redux-form';

import {
  RenderInput,
  RenderSelect,
  RenderTextArea,
  RenderDate,
  RenderCheckbox,
} from 'frontend/components/Form';
import { remove, get } from 'helpers/reduxFormArray';

const SingleLaboralExperience = ({
  jobAtrKey,
  index,
  fields,
  errors,
  onErrors,
  jobCategories,
  removeError,
  name,
}) => {
  const actualLabExp = get(fields, index);
  if (actualLabExp._destroy) {
    return (
      <>
      </>
    );
  }

  return (
    <div className="job-experience">
      <Field component={RenderInput} type="hidden" name={`${jobAtrKey}.id`} />
      <Form.Group widths="equal">
        <Field
          component={RenderInput}
          error={onErrors(errors.company)}
          name={`${jobAtrKey}.company`}
          fluid
          label="Empresa o Institución"
          placeholder="Empresa o Institución"
          required
        />
        <Field
          component={RenderSelect}
          error={onErrors(errors.job_category)}
          name={`${jobAtrKey}.job_category_id`}
          fluid
          label="Rubro"
          placeholder="Rubro"
          options={jobCategories}
          required
        />
      </Form.Group>
      <Form.Group>
        <Field
          component={RenderDate}
          error={onErrors(errors.begin_date)}
          name={`${jobAtrKey}.begin_date`}
          label="Fecha de inicio"
          placeholder="Fecha de inicio"
          width={6}
          required
        />
        <Field
          component={RenderDate}
          error={onErrors(errors.end_date)}
          name={`${jobAtrKey}.end_date`}
          disabled={actualLabExp.still_working}
          label="Fecha de fin"
          width={6}
          placeholder="Fecha de fin"
        />
        <Field
          component={RenderCheckbox}
          error={onErrors(errors.still_working)}
          name={`${jobAtrKey}.still_working`}
          width={4}
          label="¿Continúa en la actualidad?"
          placeholder="¿Continúa en la actualidad?"
        />
      </Form.Group>
      <Form.Group>
        <Field
          component={RenderInput}
          error={onErrors(errors.job_brief)}
          name={`${jobAtrKey}.job_brief`}
          fluid
          label="Puesto ocupado"
          placeholder="Puesto ocupado"
          width={12}
          required
        />
        <Field
          component={RenderInput}
          error={onErrors(errors.people_in_charge)}
          name={`${jobAtrKey}.people_in_charge`}
          fluid
          type="number"
          label="Personal a cargo"
          placeholder="Personal a cargo"
          width={4}
          required
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Field
          component={RenderTextArea}
          error={onErrors(errors.job_description)}
          name={`${jobAtrKey}.job_description`}
          fluid
          label="Descripción de las tareas y responsabilidades"
          placeholder="Descripción de las tareas y responsabilidades"
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Field
          component={RenderTextArea}
          error={onErrors(errors.achievements)}
          name={`${jobAtrKey}.achievements`}
          fluid
          label="Logros obtenidos"
          placeholder="Logros obtenidos"
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Field
          component={RenderTextArea}
          error={onErrors(errors.comments)}
          name={`${jobAtrKey}.comments`}
          fluid
          label="Informacion adicional"
          placeholder="Informacion adicional"
        />
      </Form.Group>
      <Button basic color="red" type="button" onClick={remove(fields, index, removeError, name)}>
        Eliminar antecedente laboral
      </Button>
      <Divider section />
    </div>
  );
};

const RenderLaboralExperiences = (errors, onErrors) => ({
  fields,
  ...props
}) => (
  <Segment raised color="blue">
    <Grid>
      <Grid.Row>
        <Grid.Column width={11}>
          <Header as="h3" textAlign="left">
            <Icon name="suitcase" />
            Experiencias laborales
          </Header>
        </Grid.Column>
        <Grid.Column width={5}>
          <Button type="button" size="tiny" basic color="teal" fluid onClick={() => fields.push({})}>
            <Icon name="plus" />
            Agregar antecedente laboral
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Divider />
    <>
      {fields.map((job, index) => (
        <SingleLaboralExperience
          key={index.toString()}
          jobAtrKey={job}
          index={index}
          fields={fields}
          name="job_experiences"
          errors={errors[index] || {}}
          onErrors={onErrors}
          {...props}
        />
      ))}
    </>
  </Segment>
);

const LaboralExperiences = ({ errors = [], onErrors = (e) => e, ...props }) => (
  <FieldArray
    name="job_experiences_attributes"
    rerenderOnEveryChange
    component={RenderLaboralExperiences(errors, onErrors)}
    {...props}
  />
);

export default LaboralExperiences;
