import {
  GENDER_GET_REQUEST,
  GENDER_GET_SUCCESS,
  GENDER_GET_FAILURE,

  INTEGRATOR_GENDER_GET_REQUEST,
  INTEGRATOR_GENDER_GET_SUCCESS,
  INTEGRATOR_GENDER_GET_FAILURE,

  PROVINCE_GET_REQUEST,
  PROVINCE_GET_SUCCESS,
  PROVINCE_GET_FAILURE,

  DOCUMENT_TYPE_GET_REQUEST,
  DOCUMENT_TYPE_GET_SUCCESS,
  DOCUMENT_TYPE_GET_FAILURE,

  COUNTRY_GET_REQUEST,
  COUNTRY_GET_SUCCESS,
  COUNTRY_GET_FAILURE,

  MARITAL_STATUS_GET_REQUEST,
  MARITAL_STATUS_GET_SUCCESS,
  MARITAL_STATUS_GET_FAILURE,

  ACHIEVED_STUDY_GET_REQUEST,
  ACHIEVED_STUDY_GET_SUCCESS,
  ACHIEVED_STUDY_GET_FAILURE,

  CAREER_GET_REQUEST,
  CAREER_GET_SUCCESS,
  CAREER_GET_FAILURE,

  GEM_PROVINCE_GET_REQUEST,
  GEM_PROVINCE_GET_SUCCESS,
  GEM_PROVINCE_GET_FAILURE,

  PARTY_GET_REQUEST,
  PARTY_GET_SUCCESS,
  PARTY_GET_FAILURE,

  CITY_GET_REQUEST,
  CITY_GET_SUCCESS,
  CITY_GET_FAILURE,

  PROFESSIONAL_DEV_AREA_GET_REQUEST,
  PROFESSIONAL_DEV_AREA_GET_SUCCESS,
  PROFESSIONAL_DEV_AREA_GET_FAILURE,

  JOB_CATEGORIES_GET_REQUEST,
  JOB_CATEGORIES_GET_SUCCESS,
  JOB_CATEGORIES_GET_FAILURE,

  INSTITUTIONS_GET_REQUEST,
  INSTITUTIONS_GET_SUCCESS,
  INSTITUTIONS_GET_FAILURE,

  DEGREES_GET_REQUEST,
  DEGREES_GET_SUCCESS,
  DEGREES_GET_FAILURE,

  REPRESENTATIVES_GET_REQUEST,
  REPRESENTATIVES_GET_SUCCESS,
  REPRESENTATIVES_GET_FAILURE,
} from 'constants/reference';

import InitialState from 'frontend/state/reference-datum';

export default (state = InitialState, action) => {
  switch (action.type) {
    case PROVINCE_GET_REQUEST:
    case PROFESSIONAL_DEV_AREA_GET_REQUEST:
    case INTEGRATOR_GENDER_GET_REQUEST:
    case MARITAL_STATUS_GET_REQUEST:
    case COUNTRY_GET_REQUEST:
    case DOCUMENT_TYPE_GET_REQUEST:
    case ACHIEVED_STUDY_GET_REQUEST:
    case GENDER_GET_REQUEST:
    case CAREER_GET_REQUEST:
    case JOB_CATEGORIES_GET_REQUEST:
    case INSTITUTIONS_GET_REQUEST:
    case DEGREES_GET_REQUEST:
    case REPRESENTATIVES_GET_REQUEST:
    {
      return {
        ...state,
        isFetchingData: true,
        error: null,
      };
    }
    case PROVINCE_GET_SUCCESS:
    case PROFESSIONAL_DEV_AREA_GET_SUCCESS:
    case INTEGRATOR_GENDER_GET_SUCCESS:
    case MARITAL_STATUS_GET_SUCCESS:
    case COUNTRY_GET_SUCCESS:
    case DOCUMENT_TYPE_GET_SUCCESS:
    case ACHIEVED_STUDY_GET_SUCCESS:
    case GENDER_GET_SUCCESS:
    case CAREER_GET_SUCCESS:
    case JOB_CATEGORIES_GET_SUCCESS:
    case INSTITUTIONS_GET_SUCCESS:
    case DEGREES_GET_SUCCESS:
    case REPRESENTATIVES_GET_SUCCESS:
    {
      const {
        data,
        name,
      } = action.payload;

      return {
        ...state,
        [name]: data,
        isFetchingData: false,
        error: null,
      };
    }
    case INTEGRATOR_GENDER_GET_FAILURE:
    case PROFESSIONAL_DEV_AREA_GET_FAILURE:
    case MARITAL_STATUS_GET_FAILURE:
    case COUNTRY_GET_FAILURE:
    case DOCUMENT_TYPE_GET_FAILURE:
    case ACHIEVED_STUDY_GET_FAILURE:
    case GENDER_GET_FAILURE:
    case CAREER_GET_FAILURE:
    case PROVINCE_GET_FAILURE:
    case JOB_CATEGORIES_GET_FAILURE:
    case INSTITUTIONS_GET_FAILURE:
    case DEGREES_GET_FAILURE:
    case REPRESENTATIVES_GET_FAILURE:
    {
      const {
        error,
      } = action.payload;
      return {
        ...state,
        isFetching: false,
        error,
      };
    }
    case GEM_PROVINCE_GET_REQUEST:
    case PARTY_GET_REQUEST:
    case CITY_GET_REQUEST:
    {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    }
    case GEM_PROVINCE_GET_SUCCESS:
    case PARTY_GET_SUCCESS:
    case CITY_GET_SUCCESS:
    {
      const {
        data,
        pos,
        name,
      } = action.payload;

      return {
        ...state,
        [name]: {
          ...state[name],
          [pos]: data,
        },
        isFetching: false,
        error: null,
      };
    }
    case GEM_PROVINCE_GET_FAILURE:
    case PARTY_GET_FAILURE:
    case CITY_GET_FAILURE:
    {
      const {
        error,
      } = action.payload;
      return {
        ...state,
        isFetching: false,
        error,
      };
    }
    default:
      return state;
  }
};
