import React from 'react';
import { Field } from 'redux-form';
import {
  RenderInput,
  RenderSelect,
  RenderCheckbox,
  RenderTextArea,
} from 'frontend/components/Form';
import {
  Grid,
  Divider,
  Form,
  Button,
  Icon,
  Header,
} from 'semantic-ui-react';
import { remove, get } from 'helpers/reduxFormArray';

const ComputerSkill = (props) => {
  const {
    keyName,
    index,
    fields,
    curriculumSkillLevels,
    removeError,
    name,
    errors,
    onErrors,
  } = props;

  const actualComputerSkill = get(fields, index);

  if (actualComputerSkill._destroy) {
    return (
      <>
      </>
    );
  }

  return (
    <>
      <Field component={RenderInput} type="hidden" name={`${keyName}.id`} />
      <Field
        component={RenderTextArea}
        error={onErrors(errors.skill)}
        name={`${keyName}.skill`}
        fluid
        required
        label="Conocimiento"
        placeholder="Conocimiento"
      />
      <Form.Group>
        <Field
          component={RenderSelect}
          error={onErrors(errors.level)}
          name={`${keyName}.level`}
          fluid
          width={12}
          label="Nivel"
          placeholder="Nivel"
          required
          options={curriculumSkillLevels}
        />
        <Field
          component={RenderCheckbox}
          error={onErrors(errors.certified)}
          name={`${keyName}.certified`}
          width={4}
          label="¿Posee certificado?"
        />
      </Form.Group>
      <Button type="button" basic color="red" onClick={remove(fields, index, removeError, name)}>
        Eliminar conocimiento Informático
      </Button>
      <Divider section />
    </>
  );
};

export default (errors, onErrors) => ({
  fields,
  ...props
}) => (
  <>
    <Grid>
      <Grid.Row>
        <Grid.Column width={11}>
          <Header as="h3" textAlign="left">
            <Icon name="computer" />
            Informática
          </Header>
        </Grid.Column>
        <Grid.Column width={5}>
          <Button
            type="button"
            size="tiny"
            basic
            fluid
            color="teal"
            onClick={() => fields.push({ completion: 0 })}
          >
            <Icon name="plus" />
            Agregar conocimiento Informático
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Divider />
    <>
      {fields.map((job, index) => (
        <ComputerSkill
          key={index.toString()}
          keyName={job}
          index={index}
          fields={fields}
          errors={errors[index] || {}}
          name="computer_skills"
          onErrors={onErrors}
          {...props}
        />
      ))}
    </>
  </>
);
