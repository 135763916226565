import * as Const from 'constants/public-job-offers';
import InitialState from 'frontend/state/public-job-offers';

export default (state = InitialState, action) => {
  switch (action.type) {
    case Const.PUBLIC_JOB_OFFERS_GET_REQUEST:
    case Const.PUBLIC_JOB_OFFER_GET_REQUEST:
    {
      return {
        ...state,
        isFetching: true,
        error: null,
      };
    }
    case Const.PUBLIC_JOB_OFFERS_GET_SUCCESS:
    {
      const {
        data,
        total_pages,
        total_elements,
      } = action.payload;

      return {
        ...state,
        total_elements,
        total_pages,
        public_job_offers: data,
        isFetching: false,
        error: null,
      };
    }
    case Const.PUBLIC_JOB_OFFERS_GET_FAILURE:
    case Const.PUBLIC_JOB_OFFER_GET_FAILURE:
    {
      const {
        error,
      } = action.payload;
      return {
        ...state,
        isFetching: false,
        error,
      };
    }
    case Const.PUBLIC_JOB_OFFER_GET_SUCCESS:
    {
      const {
        data,
        name,
      } = action.payload;

      return {
        ...state,
        [name]: data,
        isFetching: false,
        error: null,
      };
    }
    default:
      return state;
  }
};
