import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as referenceActions from 'frontend/actions/reference';

import LocationForm from 'frontend/components/Location/LocationForm';

class Location extends React.Component {
  constructor(props) {
    super(props);
    this.handleCountryChange = this.handleCountryChange.bind(this);
    this.handleProvinceChange = this.handleProvinceChange.bind(this);
    this.handlePartyChange = this.handlePartyChange.bind(this);
    this.useInternalData = this.useInternalData.bind(this);
    this.state = {
      useCityId: true,
      country: 'AR',
      province: 1,
      party: 1,
    };
  }

  componentDidMount() {
    const {
      actions: {
        getCountries,
        getProvinces,
        // getParties,
        // getCities,
      },
    } = this.props;
    console.log(this.props);
    getCountries();
    getProvinces();
    // getParties(province);
    // getCities(party);
  }

  useInternalData(value) {
    this.setState({ useCityId: value });
  }

  handleCountryChange(value) {
    const {
      actions: {
        getGemProvinces,
      },
      referenceDatum: {
        gemProvinces,
      },
    } = this.props;
    this.useInternalData(value === 'AR');
    if (value !== 'AR' && !gemProvinces[value]) {
      getGemProvinces(value);
    }
    this.setState((prevState) => ({
      ...prevState,
      country: value,
    }));
  }

  handleProvinceChange(value) {
    const {
      actions: {
        getParties,
      },
      referenceDatum: {
        parties,
      },
    } = this.props;
    if (!parties[value]) {
      getParties(value);
    }
    this.setState((prevState) => ({
      ...prevState,
      province: value,
    }));
  }

  handlePartyChange(value) {
    const {
      actions: {
        getCities,
      },
      referenceDatum: {
        cities,
      },
    } = this.props;
    if (!cities[value]) {
      getCities(value);
    }
    this.setState((prevState) => ({
      ...prevState,
      party: value,
    }));
  }

  render() {
    const {
      referenceDatum: {
        countries,
        provinces,
        parties,
        cities,
        gemProvinces,
      },
      countryLabel,
      countryName,
      countryErrors,
      provinceLabel,
      provinceName,
      cityLabel,
      cityName,
      partyLabel,
      partyName,
      cityErrors,
      cityTextName,
      cityTextLabel,
      cityTextErrors,
      required,
    } = this.props;
    const {
      useCityId,
      country,
      province,
      party,
    } = this.state;
    return (
      <LocationForm
        countryLabel={countryLabel}
        countryErrors={countryErrors}
        countryOptions={countries || []}
        countryName={countryName}
        provinceLabel={provinceLabel}
        provinceName={provinceName}
        provinceOptions={provinces}
        partyLabel={partyLabel}
        partyName={partyName}
        partyOptions={parties[province] || []}
        cityLabel={cityLabel}
        cityOptions={cities[party] || []}
        cityName={cityName}
        cityErrors={cityErrors}
        cityTextName={cityTextName}
        cityTextLabel={cityTextLabel}
        cityTextErrors={cityTextErrors}
        provinceGemOptions={gemProvinces[country] || []}
        useCityId={useCityId}
        required={required}
        onCountryChange={this.handleCountryChange}
        onProvinceChange={this.handleProvinceChange}
        onPartyChange={this.handlePartyChange}
      />
    );
  }
}

const mapStateToProps = (state) => ({
  referenceDatum: state.referenceDatum,
  form: state.form,
});

function mapDispatchToProps(dispatch) {
  return ({
    actions: bindActionCreators({
      ...referenceActions,
    }, dispatch),
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(Location);
