import axios from 'helpers/axios';

export const asOptionsForSelect = (array) => array.map((v) => ({
  text: v[1],
  value: v[0],
}));

export const achiviedStudiesOptions = (array) => array.map((v) => ({
  value: v[0],
  text: v[1],
  degree: v[2].toString(),
  complete: v[3].toString(),
}));

export const jobCategoriesMappedOptions = (arr) => (
  arr.map((v) => ({
    text: `${v[1]}(${v[2]})`,
    value: v[0],
  }))
);

const endpointConfig = (resource, query = {}, prefix = 'ref') => ({
  method: 'get',
  url: `/api/v1/${prefix}/${resource}.json`,
  params: query,
});

export const fetchFromAPI = (resource, query, prefix) => (
  axios.request(endpointConfig(resource, query, prefix))
);
