import React from 'react';
import {
  Button,
  Dimmer,
  Loader, Placeholder, Segment,
  Table,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import Moment from 'frontend/components/Moment';
import SharePublicJobOffer from './SharePublicJobOffer';

const PublicJobOffersList = ({ publicJobOffers, isFetching }) => {
  const placeholder = (
    <Segment>
      <Placeholder fluid className="y-spaced">
        {[0, 1, 2, 3, 4, 5, 6, 7, 8, 9].map((i) => (
          <Placeholder.Line key={`pjol-ph-${i}`} length="full" />
        ))}
      </Placeholder>
    </Segment>
  );
  const table = (
    <Table padded compact verticalAlign="middle" className="public-job-offers-list">
      <Table.Body>
        <Table.Row key="0">
          <Table.Cell>Anuncio</Table.Cell>
          <Table.Cell>Cargo</Table.Cell>
          <Table.Cell>Fecha del pedido</Table.Cell>
        </Table.Row>
        {
          publicJobOffers.map((job) => (
            <Table.Row key={job.id}>
              <Table.Cell
                width="6"
                className="public-job-offer-title"
              >
                <a href={`busquedas/${job.id}`}>{job.ad_text}</a>
              </Table.Cell>
              <Table.Cell width="6">{job.position_title}</Table.Cell>
              <Table.Cell width="2">
                <Moment fromNow withTitle titleFormat="LL">{job.start_date}</Moment>
              </Table.Cell>
              <Table.Cell width="2">
                <Button
                  content="Ver"
                  icon="expand"
                  color="blue"
                  labelPosition="left"
                  size="tiny"
                  as={Link}
                  to={`busquedas/${job.id}`}
                />
                <SharePublicJobOffer publicJobOffer={job} size="tiny" />
              </Table.Cell>
            </Table.Row>
          ))
        }
      </Table.Body>
    </Table>
  );
  return (
    <>
      <Dimmer.Dimmable dimmed={isFetching}>
        {isFetching ? placeholder : table}

        <Dimmer active={isFetching}>
          <Loader>Cargando búsquedas laborales</Loader>
        </Dimmer>
      </Dimmer.Dimmable>
    </>
  );
};

export default PublicJobOffersList;
