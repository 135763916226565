import React from 'react';
import { Button, Popup } from 'semantic-ui-react';
import {
  FacebookIcon,
  FacebookShareButton,
  LinkedinIcon,
  LinkedinShareButton,
  TwitterIcon,
  TwitterShareButton,
  WhatsappIcon,
  WhatsappShareButton,
} from 'react-share';

const SharePublicJobOffer = ({ publicJobOffer, size }) => {
  const url = `${window.location.origin}/posiciones/${publicJobOffer.id}`;
  return (
    <Popup
      flowing
      pinned
      on="click"
      position="top right"
      trigger={<Button size={size} icon="share" color="green" basic />}
      className="share-public-job-offer-popup"
    >
      <div>Compartir esta búsqueda en</div>
      <FacebookShareButton url={url} quote={publicJobOffer.ad_text} hashtag="#prolab #unlp">
        <FacebookIcon size={32} borderRadius={4} />
      </FacebookShareButton>
      <LinkedinShareButton url={url} title={publicJobOffer.ad_text} summary={publicJobOffer.position_description} source="ProLab UNLP">
        <LinkedinIcon size={32} borderRadius={4} />
      </LinkedinShareButton>
      <TwitterShareButton url={url} title={publicJobOffer.ad_text} hashtags={['prolab', 'unlp']} via="ProLab UNLP">
        <TwitterIcon size={32} borderRadius={4} />
      </TwitterShareButton>
      <WhatsappShareButton url={url} title={publicJobOffer.ad_text} separator=" ">
        <WhatsappIcon size={32} borderRadius={4} />
      </WhatsappShareButton>
    </Popup>
  );
};

export default SharePublicJobOffer;
