import {
  CURRICULUM_GET_FAILURE,
  CURRICULUM_GET_SUCCESS,
  CURRICULUM_GET_REQUEST,

  CURRICULUM_FILES_GET_FAILURE,
  CURRICULUM_FILES_GET_SUCCESS,
  CURRICULUM_FILES_GET_REQUEST,

  CURRICULUM_UPDATE_FAILURE,
  CURRICULUM_UPDATE_SUCCESS,
  CURRICULUM_UPDATE_REQUEST,

  CURRICULUM_FILES_POST_REQUEST,
  CURRICULUM_FILES_POST_SUCCESS,
  CURRICULUM_FILES_POST_FAILURE,

  CURRICULUM_FILES_REMOVE_REQUEST,
  CURRICULUM_FILES_REMOVE_SUCCESS,
  CURRICULUM_FILES_REMOVE_FAILURE,

  ON_DISMISS_MESSAGE,
  ON_REMOVE_ERROR,
} from 'constants/curriculum';

import axios from 'helpers/axios';

const mapArrayFromOtherToEmpty = (arr) => (
  arr.map((el) => {
    const newElement = el;
    if (el.institution_id === 'other') {
      newElement.institution_id = '';
    }
    if (el.degree_id === 'other') {
      newElement.degree_id = '';
    }
    return newElement;
  })
);

export const getCurriculumFromLoggedApplicant = () => (dispatch) => {
  dispatch({ type: CURRICULUM_GET_REQUEST });
  return axios({
    url: '/api/v1/curriculums/current',
    method: 'get',
  }).then(
    (res) => {
      dispatch({ type: CURRICULUM_GET_SUCCESS, payload: res.data });
    },
  ).catch((err) => dispatch({
    type: CURRICULUM_GET_FAILURE,
    payload: err,
  }));
};

export const getCVFiles = () => (dispatch) => {
  dispatch({ type: CURRICULUM_FILES_GET_REQUEST });
  return axios({
    url: '/api/v1/curriculums/files',
    method: 'get',
  }).then(
    (res) => {
      dispatch({ type: CURRICULUM_FILES_GET_SUCCESS, payload: res.data });
    },
  ).catch((err) => dispatch({
    type: CURRICULUM_FILES_GET_FAILURE,
    payload: err,
  }));
};

export const updateLoggedApplicantCurriculum = (data) => (dispatch) => {
  dispatch({ type: CURRICULUM_UPDATE_REQUEST });
  return axios({
    url: '/api/v1/curriculums/current',
    method: 'patch',
    data: {
      curriculum: {
        ...data,
        formal_studies_attributes: mapArrayFromOtherToEmpty(data.formal_studies_attributes),
      },
    },
  }).then(
    (res) => {
      dispatch({ type: CURRICULUM_UPDATE_SUCCESS, payload: res.data });
    },
  ).catch((err) => dispatch({
    type: CURRICULUM_UPDATE_FAILURE,
    payload: {
      errors: err.response.data,
    },
  }));
};

export const uploadFiles = (data) => (dispatch) => {
  dispatch({ type: CURRICULUM_FILES_POST_REQUEST });
  return axios({
    url: '/api/v1/curriculums/files',
    method: 'post',
    headers: {
      'Content-Type': 'application/x-www-form-urlencoded',
    },
    data,
  }).then(
    (res) => {
      dispatch({ type: CURRICULUM_FILES_POST_SUCCESS, payload: res.data });
    },
  ).catch((err) => dispatch({
    type: CURRICULUM_FILES_POST_FAILURE,
    payload: {
      errors: err.response.data,
      code: err.response.code,
    },
  }));
};

export const removeFile = (id) => (dispatch) => {
  dispatch({ type: CURRICULUM_FILES_REMOVE_REQUEST });
  return axios({
    data: { id },
    url: '/api/v1/curriculums/remove_file',
    method: 'post',
  }).then(
    (res) => {
      dispatch({ type: CURRICULUM_FILES_REMOVE_SUCCESS, payload: res.data });
    },
  ).catch((err) => dispatch({
    type: CURRICULUM_FILES_REMOVE_FAILURE,
    payload: {
      errors: err.response.data,
      code: err.response.code,
    },
  }));
};

export const dismissMessage = (message) => (dispatch) => (
  dispatch({
    type: ON_DISMISS_MESSAGE,
    payload: { message },
  })
);

export const removeError = (key, index) => (dispatch) => (
  dispatch({
    type: ON_REMOVE_ERROR,
    payload: {
      key,
      index,
    },
  })
);
