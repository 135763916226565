import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Icon } from 'semantic-ui-react';
import { Toast } from 'frontend/components/Form';
import * as ApplicantActions from 'frontend/actions/applicant';
import * as referenceActions from 'frontend/actions/reference';
import RegisterApplicantContainer from 'frontend/components/RegisterApplicant/RegisterApplicantFormContainer';

class RegisterApplicant extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showAchievedStudies = this.showAchievedStudies.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
  }

  componentDidMount() {
    const {
      actions: {
        getGenders,
        getDocumentTypes,
        getMaritalStatuses,
        getAchievedStudies,
        getCareers,
        getIntegratorGenders,
        getProfessionalDevAreas,
      },
    } = this.props;
    getDocumentTypes();
    getGenders();
    getIntegratorGenders();
    getMaritalStatuses();
    getAchievedStudies();
    getCareers();
    getProfessionalDevAreas();
  }

  handleDismiss(message) {
    return () => {
      const {
        actions: {
          dismissMessage,
        },
      } = this.props;
      dismissMessage(message);
    };
  }

  handleSubmit(values) {
    const url = '/api/v1/applicants/register';
    const {
      actions: {
        registerApplicant,
      },
    } = this.props;
    registerApplicant(values, url).then(() => {
      const {
        applicant: {
          onSuccess,
        },
      } = this.props;
      if (onSuccess) {
        setTimeout(() => { window.location = '/iniciar-sesion-postulante'; }, 3500);
      }
    });
  }

  showAchievedStudies(id, arr) {
    if (id) {
      return arr.find((level) => level.value === id).degree === 'true';
    } return false;
  }

  render() {
    const {
      applicant: {
        isFetching,
        errors,
        hasErrors,
        message,
        onSuccess,
      },
      referenceDatum: {
        countries,
        genders,
        iGenders,
        documentTypes,
        maritalStatuses,
        achievedStudies,
        careers,
        professionalDevAreas,
      },
    } = this.props;
    return (
      <>
        {
          onSuccess
          && (
            <Toast
              success
              icon={<Icon color="green" name="check" />}
              onDismiss={this.handleDismiss('onSuccess')}
              header="Te registraste correctamente"
              content="Te enviamos un correo en tu casilla de mail para que confirmes tu usuario"
            />
          )
        }
        {
          hasErrors
          && (
            <Toast
              negative
              icon={<Icon color="red" name="x" />}
              onDismiss={this.handleDismiss('hasErrors')}
              content={message}
            />
          )
        }
        <RegisterApplicantContainer
          fetching={isFetching}
          iGenders={iGenders}
          genders={genders}
          achievedStudies={achievedStudies}
          countries={countries}
          documentTypes={documentTypes}
          professionalDevAreas={professionalDevAreas}
          maritalStatuses={maritalStatuses}
          careers={careers}
          errors={errors}
          showAchievedStudies={this.showAchievedStudies}
          onSubmit={this.handleSubmit}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  applicant: state.applicant,
  referenceDatum: state.referenceDatum,
  form: state.form,
});

function mapDispatchToProps(dispatch) {
  return ({
    actions: bindActionCreators({
      ...referenceActions,
      ...ApplicantActions,
    }, dispatch),
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(RegisterApplicant);
