import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as enumsActions from 'frontend/actions/enums';
import * as referenceActions from 'frontend/actions/reference';
import { WorkStudiesForm } from 'frontend/components/CurriculumForm/partials';

const achievedStudyIsComplete = (arr, id) => {
  const data = arr.find((study) => study.value === id);
  if (data) {
    return data.complete;
  }
  return false;
};

const changeProgress = (fields, index, setRerender, number, increase = false) => () => {
  const actualElement = fields.get(index);
  if (increase && actualElement.completion < 1) {
    actualElement.completion = parseFloat((actualElement.completion + 0.1).toFixed(1));
  } else if (!increase && actualElement.completion > 0) {
    actualElement.completion = parseFloat((actualElement.completion - 0.1).toFixed(1));
  }
  fields.push(actualElement);
  fields.remove(index);
  fields.move(fields.length - 1, index);
  setRerender(number + 1);
};

const usePreinitializelWorkStudies = (actions) => {
  const {
    getAchievedStudies,
    getInstitutions,
    getDegrees,
    getLanguageLevels,
    getProfessionalDevAreas,
    getCourseDurationUnits,
    getCurriculumSkillLevels,
  } = actions;

  useEffect(() => {
    getInstitutions();
    getAchievedStudies();
    getDegrees();
    getLanguageLevels();
    getProfessionalDevAreas();
    getCourseDurationUnits();
    getCurriculumSkillLevels();
  }, [
    getInstitutions,
    getAchievedStudies,
    getDegrees,
    getLanguageLevels,
    getProfessionalDevAreas,
    getCourseDurationUnits,
    getCurriculumSkillLevels,
  ]);
};
const WorkStudies = ({
  actions,
  ...props
}) => {
  usePreinitializelWorkStudies(actions);

  return (
    <>
      <WorkStudiesForm
        achievedStudyIsComplete={achievedStudyIsComplete}
        changeProgress={changeProgress}
        {...props}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  institutions: state.referenceDatum.institutions,
  achievedStudies: state.referenceDatum.achievedStudies,
  degrees: state.referenceDatum.degrees,
  languageLevels: state.enums.languageLevels,
  professionalDevAreas: state.referenceDatum.professionalDevAreas,
  courseDurationUnits: state.enums.courseDurationUnits,
  curriculumSkillLevels: state.enums.curriculumSkillLevels,
});

function mapDispatchToProps(dispatch) {
  return ({
    actions: bindActionCreators({
      ...enumsActions,
      ...referenceActions,
    }, dispatch),
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkStudies);
