import React from 'react';
import {
  Menu,
  Dropdown,
  Image,
  Button,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import prolabLogo from 'images/prolab-logo';
import offersTitle from 'images/offers-title';

const redirectAdmin = () => { window.location = '/api/v1/sessions/admins'; };

const loginText = (loggedUser, isFetching) => (isFetching ? 'Iniciando sesión...' : loggedUser.email);

const ProfileNavbar = ({ loggedUser, isFetching }) => (
  <Menu.Menu position="right">
    { loggedUser.role === 'admin' && (
    <Menu.Item as={Button} onClick={() => { window.location = '/administracion#/'; }}>
      Administracion
    </Menu.Item>
    )}
    <Menu.Item as={Link} to="/">
      Búsquedas abiertas
    </Menu.Item>
    <Menu.Item as={Dropdown} text={loginText(loggedUser, isFetching)}>
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/perfil">Perfil</Dropdown.Item>
        {loggedUser.role !== 'admin' && (
          <Dropdown.Item as={Link} to="/curriculum">Cargar CV</Dropdown.Item>
        )}
        <Dropdown.Divider />
        <Dropdown.Item as={Link} to="/cerrar-sesion">Cerrar sesión</Dropdown.Item>
      </Dropdown.Menu>
    </Menu.Item>
  </Menu.Menu>
);

const SignInUpNavbar = () => (
  <Menu.Menu position="right">
    <Menu.Item as={Link} to="/">
      Búsquedas abiertas
    </Menu.Item>
    <Menu.Item as={Dropdown} text="Registrarse">
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/registrar-postulante">Como postulante</Dropdown.Item>
      </Dropdown.Menu>
    </Menu.Item>
    <Menu.Item as={Dropdown} text="Iniciar sesión">
      <Dropdown.Menu>
        <Dropdown.Item as={Link} to="/iniciar-sesion-postulante">Como postulante</Dropdown.Item>
        <Dropdown.Item
          onClick={redirectAdmin}
          to="/iniciar-sesion-postulante"
        >
          Como administrador
        </Dropdown.Item>
      </Dropdown.Menu>
    </Menu.Item>
  </Menu.Menu>
);

const Navbar = ({ loggedUser, isFetching, showOffersTitle }) => (
  <Menu inverted pointing className="navbar">
    <Menu.Item className="logo-item">
      <Image src={prolabLogo} size="small" as={Link} to="/" />
    </Menu.Item>
    {
      showOffersTitle
      && (
        <Menu.Menu position="right">
          <Menu.Item className="offers-title">
            <Image src={offersTitle} size="medium" as={Link} to="/" />
          </Menu.Item>
        </Menu.Menu>
      )
    }

    { (isFetching || loggedUser)
      && <ProfileNavbar loggedUser={loggedUser} isFetching={isFetching} /> }
    { !(isFetching || loggedUser) && SignInUpNavbar() }
  </Menu>
);

Navbar.propTypes = {
  loggedUser: PropTypes.shape({
    email: PropTypes.string,
  }),
  isFetching: PropTypes.bool,
  showOffersTitle: PropTypes.bool,
};

Navbar.defaultProps = {
  loggedUser: undefined,
  isFetching: false,
  showOffersTitle: false,
};

export default Navbar;
