import React from 'react';
import { Segment } from 'semantic-ui-react';
import { FieldArray } from 'redux-form';
import RenderFormalStudies from './FormalStudies';
import RenderLanguages from './Languages';
import RenderCourses from './Courses';
import RenderComputerSkill from './ComputerSkills';

const WorkStudies = ({ errors = [], onErrors = (e) => e, ...props }) => (
  <>
    <Segment raised color="blue">
      <FieldArray
        name="formal_studies_attributes"
        rerenderOnEveryChange
        component={RenderFormalStudies(errors.formal_studies, onErrors)}
        {...props}
      />
    </Segment>
    <Segment raised color="blue">
      <FieldArray
        name="language_studies_attributes"
        rerenderOnEveryChange
        component={RenderLanguages(errors.language_studies, onErrors)}
        {...props}
      />
    </Segment>
    <Segment raised color="blue">
      <FieldArray
        name="computing_skills_attributes"
        rerenderOnEveryChange
        component={RenderComputerSkill(errors.computing_skills, onErrors)}
        {...props}
      />
    </Segment>
    <Segment raised color="blue">
      <FieldArray
        name="courses_attributes"
        rerenderOnEveryChange
        component={RenderCourses(errors.courses, onErrors)}
        {...props}
      />
    </Segment>
  </>
);

export default WorkStudies;
