import React from 'react';
import { Form } from 'semantic-ui-react';
import { DateInput } from 'semantic-ui-calendar-react';
import 'moment/locale/es';

const renderDate = ({
  error,
  input,
  label,
  placeholder,
  disabled,
  required,
  inline,
  dateFormat,
  width,
  inputProps,
}) => (
  <Form.Field
    width={width}
    required={required}
    inline={inline}
    disabled={disabled}
    error={Boolean(error)}
  >
    <label>{label}</label>
    <DateInput
      closable
      animation="none"
      clearable={!required}
      required={required}
      localization="es"
      {...inputProps}
      name={input.name}
      value={input.value}
      minDate={input.minDate}
      maxDate={input.maxDate}
      dateFormat={dateFormat}
      placeholder={placeholder}
      onChange={(e, { value }) => input.onChange(value)}
    />
    <div>
      {error && <span style={{ color: 'red' }}>{error}</span>}
    </div>
  </Form.Field>
);

export default renderDate;
