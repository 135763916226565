import React from 'react';
import {
  Button,
  Grid,
  Image,
  Message,
  Segment,
  Form,
  Header,
  Divider,
} from 'semantic-ui-react';
import prolabLogo from 'images/prolab-logo';
import { Field } from 'redux-form';
import { RenderInput } from 'frontend/components/Form';
import Captcha from '../../containers/Login/Captcha';

const LoginForm = (props) => {
  const {
    title,
    urlregister,
    handleSubmit,
    onSubmit,
    applicant,
    onRedirect,
    failNumber,
    captchaValid,
  } = props;
  const captchaValidationNeeaded = failNumber > 2;
  const disabled = captchaValidationNeeaded && !captchaValid;
  return (
    <Grid textAlign="center" verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal">
          <Image src={prolabLogo} />
          <br />
          Iniciar sesión como
          {' '}
          {title}
        </Header>
        <Form size="large" onSubmit={handleSubmit(onSubmit)}>
          <Segment stacked>
            <Field
              fluid
              component={RenderInput}
              icon="user"
              label="Usuario"
              iconPosition="left"
              placeholder="Usuario"
              required
              name="username"
            />
            <Field
              component={RenderInput}
              fluid
              icon="lock"
              label="Clave"
              iconPosition="left"
              placeholder="Clave"
              type="password"
              required
              name="password"
            />
            {captchaValidationNeeaded && <Captcha />}
            {disabled && <p><i>Es necesario confirmar la validacion de arriba</i></p>}
            <Button color="teal" fluid size="large" disabled={disabled}>
              Iniciar sesión
            </Button>
          </Segment>
        </Form>
        <Message>
          <a href="/solicitud-cambio">
            ¿Te olvidaste la clave?
          </a>
        </Message>
        <Message>
          Si aún no te llego el mail de confirmacion,
          {' '}
          <a href="/reenviar-confirmacion">
            {' '}
            podes reenviarlo
            {' '}
          </a>
        </Message>

        <Message>
          Si aún no tenés una cuenta,
          {' '}
          <a href={urlregister}>
            {' '}
            registrate como
            {' '}
            {title}
            {' '}
          </a>
        </Message>
        {applicant
          && (
            <>
              <Divider horizontal>
                Si tenés usuario de Acceso Único de la UNLP
              </Divider>
              <Button
                fluid
                size="large"
                color="blue"
                onClick={onRedirect}
              >
                Iniciá sesión usando tu cuenta UNLP
              </Button>
            </>
          )}
      </Grid.Column>
    </Grid>
  );
};

export default LoginForm;
