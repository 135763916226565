export const CURRICULUM_GET_REQUEST = 'curriculum-get-request';
export const CURRICULUM_GET_SUCCESS = 'curriculum-get-success';
export const CURRICULUM_GET_FAILURE = 'curriculum-get-failure';

export const CURRICULUM_FILES_GET_REQUEST = 'curriculum-files-get-request';
export const CURRICULUM_FILES_GET_SUCCESS = 'curriculum-files-get-success';
export const CURRICULUM_FILES_GET_FAILURE = 'curriculum-files-get-failure';

export const CURRICULUM_FILES_POST_REQUEST = 'curriculum-files-post-request';
export const CURRICULUM_FILES_POST_SUCCESS = 'curriculum-files-post-success';
export const CURRICULUM_FILES_POST_FAILURE = 'curriculum-files-post-failure';

export const CURRICULUM_FILES_REMOVE_REQUEST = 'curriculum-files-remove-request';
export const CURRICULUM_FILES_REMOVE_SUCCESS = 'curriculum-files-remove-success';
export const CURRICULUM_FILES_REMOVE_FAILURE = 'curriculum-files-remove-failure';

export const CURRICULUM_UPDATE_REQUEST = 'curriculum-update-request';
export const CURRICULUM_UPDATE_SUCCESS = 'curriculum-update-success';
export const CURRICULUM_UPDATE_FAILURE = 'curriculum-update-failure';

export const ON_DISMISS_MESSAGE = 'on-dismiss-message';
export const ON_REMOVE_ERROR = 'on-remove-error';
