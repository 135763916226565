import React from 'react';
import {
  Form,
  Dimmer,
  Container,
  Button,
  Header,
  Loader,
} from 'semantic-ui-react';
import PersonalInfo from './partials/PersonalInfo';
import Address from './partials/Address';
import AchievedStudies from './partials/AchievedStudies';
import Experience from './partials/Experience';
import Contact from './partials/Contact';

export const Profile = (props) => (
  <>
    <PersonalInfo {...props} />
    <Address {...props} />
    <AchievedStudies {...props} />
    <Experience {...props} />
    <Contact {...props} />
  </>
);

export const ProfileForm = (props) => {
  const {
    handleSubmit,
    onSubmit,
    fetching,
  } = props;
  return (
    <Container style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>
      <Dimmer active={fetching}>
        <Loader> Cargando</Loader>
      </Dimmer>
      <Header as="h2">Completar perfil</Header>
      <Form onSubmit={handleSubmit(onSubmit)}>
        <Profile {...props} />
        <Button primary fluid>Actualizar perfil</Button>
      </Form>
    </Container>
  );
};
