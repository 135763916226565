import React, { useState, useEffect } from 'react';
import { Field } from 'redux-form';
import {
  RenderInput,
  RenderSelect,
} from 'frontend/components/Form';
import {
  Grid,
  Divider,
  Form,
  Button,
  Icon,
  Header,
  Progress,
} from 'semantic-ui-react';
import { remove, get } from 'helpers/reduxFormArray';

const FormalStudy = (props) => {
  const {
    keyName,
    index,
    fields,
    achievedStudies,
    errors,
    onErrors,
    institutions,
    achievedStudyIsComplete,
    removeError,
    name,
    degrees,
    changeProgress,
  } = props;

  const actualFormalStudy = get(fields, index);
  const complete = achievedStudyIsComplete(achievedStudies, actualFormalStudy.achieved_study_id);

  const [reRender, setRerender] = useState(0);
  const [percent, setPercent] = useState('');

  useEffect(() => {
    setPercent(actualFormalStudy.completion * 100);
  }, [actualFormalStudy.completion, reRender]);

  if (actualFormalStudy._destroy) {
    return (
      <>
      </>
    );
  }

  return (
    <>
      <Field component={RenderInput} type="hidden" name={`${keyName}.id`} />
      <Form.Group widths="equal">
        <Field
          component={RenderSelect}
          error={onErrors(errors.achieved_study)}
          name={`${keyName}.achieved_study_id`}
          fluid
          label="Nivel de estudio"
          placeholder="Nivel de estudio"
          required
          options={achievedStudies}
        />
        <Field
          component={RenderSelect}
          error={onErrors(errors.intitution)}
          name={`${keyName}.institution_id`}
          fluid
          label="Institución"
          placeholder="Institución"
          options={[...institutions, { value: 'other', text: 'Otra institución' }]}
          required
        />
        {
          actualFormalStudy.institution_id === 'other'
          && (
            <Field
              component={RenderInput}
              error={onErrors(errors.institution_name)}
              name={`${keyName}.institution_name`}
              fluid
              label="Nombre de la institución"
              placeholder="Nombre de la institución"
              required
            />
          )
        }
      </Form.Group>
      <Form.Group widths="equal">
        <Field
          component={RenderSelect}
          error={onErrors(errors.degree)}
          name={`${keyName}.degree_id`}
          fluid
          label="Título"
          placeholder="Título"
          options={[...degrees, { value: 'other', text: 'Sin título' }]}
          required
        />
        <Field
          component={RenderInput}
          error={onErrors(errors.start_year)}
          name={`${keyName}.start_year`}
          label="Año de ingreso"
          type="number"
          placeholder="Año de ingreso"
          required
        />
        {
          complete === 'true'
          && (
            <Field
              component={RenderInput}
              error={onErrors(errors.end_year)}
              name={`${keyName}.end_year`}
              label="Año de egreso"
              type="number"
              placeholder="Año de egreso"
            />
          )
        }
      </Form.Group>
      <div className="formal-study-completion">
        <div className="bar">
          <label>Porcentaje de avance en el estudio</label>
          <Progress percent={complete === 'true' ? 100 : percent} indicating autoSuccess progress />
          <Field component={RenderInput} type="hidden" name={`${keyName}.completion`} />
        </div>
        <div className="options">
          <label>Aumentar/disminuir</label>
          <br />
          <Button
            className="option"
            disabled={percent === 100 || complete === 'true'}
            type="button"
            size="small"
            color="green"
            onClick={changeProgress(fields, index, setRerender, reRender, true)}
          >
            +
          </Button>
          <Button
            className="option"
            disabled={percent === 0 || complete === 'true'}
            size="small"
            type="button"
            color="red"
            onClick={changeProgress(fields, index, setRerender, reRender)}
          >
            -
          </Button>
        </div>
      </div>
      <Button type="button" basic color="red" onClick={remove(fields, index, removeError, name)}>
        Eliminar estudio
      </Button>
      <Divider section />
    </>
  );
};

export default (errors, onErrors) => ({
  fields,
  ...props
}) => (
  <>
    <Grid>
      <Grid.Row>
        <Grid.Column width={11}>
          <Header as="h3" textAlign="left">
            <Icon name="book" />
            Estudios formales
          </Header>
        </Grid.Column>
        <Grid.Column width={5}>
          <Button
            type="button"
            size="tiny"
            basic
            fluid
            color="teal"
            onClick={() => fields.push({ completion: 0 })}
          >
            <Icon name="plus" />
            Agregar estudio
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Divider />
    <>
      {fields.map((job, index) => (
        <FormalStudy
          key={index.toString()}
          keyName={job}
          index={index}
          fields={fields}
          errors={errors[index] || {}}
          name="formal_studies"
          onErrors={onErrors}
          {...props}
        />
      ))}
    </>
  </>
);
