import * as Const from 'constants/public-job-offers';
import axios from 'helpers/axios';

export const getPublicJobOffers = (page = 1, filter = '') => (dispatch) => {
  dispatch({ type: Const.PUBLIC_JOB_OFFERS_GET_REQUEST });
  return axios({
    url: `/api/v1/public_job_offers?page=${page}&filter=${filter}`,
    method: 'get',
  }).then(
    (res) => {
      dispatch({
        type: Const.PUBLIC_JOB_OFFERS_GET_SUCCESS,
        payload: res.data,
      });
    },
  ).catch((err) => dispatch({
    type: Const.PUBLIC_JOB_OFFERS_GET_FAILURE,
    payload: {
      errors: err.response.data,
    },
  }));
};

export const getPublicJobOffer = (id) => (dispatch) => {
  dispatch({ type: Const.PUBLIC_JOB_OFFER_GET_REQUEST });
  return axios({
    url: `/api/v1/public_job_offers/${id}`,
    method: 'get',
  }).then(
    (res) => {
      dispatch({
        type: Const.PUBLIC_JOB_OFFER_GET_SUCCESS,
        payload: {
          data: res.data,
          name: 'public_job_offer',
        },
      });
    },
  ).catch((err) => dispatch({
    type: Const.PUBLIC_JOB_OFFER_GET_FAILURE,
    payload: {
      errors: err.response.data,
    },
  }));
};
