import {
  APPLICANT_GET_FAILURE,
  APPLICANT_GET_REQUEST,
  APPLICANT_GET_SUCCESS,

  REGISTER_APPLICANT_FAILURE,
  REGISTER_APPLICANT_SUCCESS,
  REGISTER_APPLICANT_REQUEST,
  ON_DISMISS_MESSAGE,

  APPLICANT_PROFILE_GET_REQUEST,
  APPLICANT_PROFILE_GET_SUCCESS,
  APPLICANT_PROFILE_GET_FAILURE,

  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILURE,
} from 'frontend/constants/applicant';

import initialState from 'frontend/state/applicant';

export default (state = initialState, action) => {
  switch (action.type) {
    case REGISTER_APPLICANT_REQUEST:
    case APPLICANT_GET_REQUEST:
    case APPLICANT_PROFILE_GET_REQUEST:
    case PROFILE_UPDATE_REQUEST: {
      return {
        ...state,
        onSuccess: false,
        isFetching: true,
        hasErrors: false,
      };
    }
    case APPLICANT_GET_SUCCESS: {
      const {
        email,
        profile: {
          name,
          surname,
          i_gender_id,
          i_document_type_id,
          document_number,
          birth_date,
        },
      } = action.payload;

      const str = birth_date.split('-');

      let date;

      if (str.length > 2) {
        date = `${str[2]}-${str[1]}-${str[0]}`;
      }

      return {
        ...state,
        email,
        profile: {
          ...state.profile,
          name,
          surname,
          i_gender_id: parseInt(i_gender_id, 10),
          i_document_type_id: parseInt(i_document_type_id, 10),
          document_number,
          birth_date: date,
        },
        isFetching: false,
        enableReinitialize: false,
        errors: '',
      };
    }
    case PROFILE_UPDATE_SUCCESS:
    case REGISTER_APPLICANT_SUCCESS: {
      const { data } = action.payload;
      return {
        ...state,
        isFetching: false,
        onSuccess: true,
        errors: '',
        username: data.username,
        email: data.email,
        profile: {
          ...state.profile,
          ...data.profile,
          i_gender_id: parseInt(data.profile.i_gender_id, 10),
          i_document_type_id: parseInt(data.profile.i_document_type_id, 10),
          job_expectation_ids: data.profile.job_expectations.map((each) => parseInt(each.id, 10)),
          job_experience_ids: data.profile.job_experiences.map((each) => parseInt(each.id, 10)),
          profile_phone: [
            {
              number: data.profile.phones.find((each) => each.phone_type === 'land_line').number,
            },
            {
              number: data.profile.phones.find((each) => each.phone_type === 'cell_phone').number,
            },
          ],
        },
      };
    }
    case REGISTER_APPLICANT_FAILURE:
    case APPLICANT_GET_FAILURE:
    case APPLICANT_PROFILE_GET_FAILURE:
    case PROFILE_UPDATE_FAILURE: {
      const {
        code,
        errors,
      } = action.payload;
      let message;
      if (code === 500) {
        message = 'Ocurrió un error en el servidor, intente más tarde';
      } else {
        message = 'Hay errores en el formulario';
      }
      return {
        ...state,
        errors,
        message,
        hasErrors: true,
        isFetching: false,
      };
    }
    case APPLICANT_PROFILE_GET_SUCCESS: {
      const { data } = action.payload;
      const findPhone = (phones, type) => {
        const match = phones.find((each) => each.phone_type === type);
        return match && match.number;
      };
      return {
        ...state,
        isFetching: false,
        username: data.username,
        email: data.email,
        profile: {
          ...state.profile,
          ...data.profile,
          i_gender_id: parseInt(data.profile.i_gender_id, 10),
          i_document_type_id: parseInt(data.profile.i_document_type_id, 10),
          job_expectation_ids: data.profile.job_expectations.map((each) => parseInt(each.id, 10)),
          job_experience_ids: data.profile.job_experiences.map((each) => parseInt(each.id, 10)),
          profile_phone: [
            {
              number: findPhone(data.profile.phones, 'land_line'),
            },
            {
              number: findPhone(data.profile.phones, 'cell_phone'),
            },
          ],
        },
      };
    }
    case ON_DISMISS_MESSAGE: {
      const {
        message,
      } = action.payload;
      return {
        ...state,
        [message]: false,
      };
    }
    default:
      return state;
  }
};
