import React, { useState } from 'react';
import {
  Button,
  Grid,
  Image,
  Segment,
  Form,
  Header,
  Input,
  Icon,
} from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { Toast } from 'frontend/components/Form';
import prolabLogo from 'images/prolab-logo';
import * as emailActions from 'frontend/actions/email';
import { connect } from 'react-redux';

const ChangePasswordRequest = (props) => {
  const {
    actions: {
      updatePassword,
      dismissMessage,
    },
    hasError,
    error,
    errors,
    success,
  } = props;
  const [confirmPassword, setConfirmPassword] = useState('');
  const [password, setPassword] = useState('');
  const [code, setCode] = useState('');
  const disabled = code === '' || password === '' || confirmPassword === '';

  const handleSubmit = () => {
    if (!disabled) updatePassword(password, confirmPassword, code);
  };

  const style = success ? { maxWidth: 700 } : { maxWidth: 450 };
  return (
    <Grid textAlign="center" verticalAlign="middle">
      <Grid.Column style={style}>
        {
          !success
            ? (
              <>
                <Header as="h2" color="teal">
                  <Image src={prolabLogo} />
                  <br />
                  Cambio de clave
                </Header>
                <Form size="large" onSubmit={handleSubmit}>
                  <Segment stacked>
                    <Form.Field required style={{ textAlign: 'left' }}>
                      <label>Clave</label>
                      <Input
                        type="password"
                        fluid
                        onChange={(e) => setPassword(e.target.value)}
                        placeholder="Clave"
                        name="password"
                      />
                      <div>
                        {
                          errors
                          && errors.password
                          && <span style={{ color: 'red' }}>{errors.password.join(', ')}</span>
                        }
                      </div>
                    </Form.Field>
                    <Form.Field required style={{ textAlign: 'left' }}>
                      <label>Repetir clave</label>
                      <Input
                        type="password"
                        fluid
                        onChange={(e) => setConfirmPassword(e.target.value)}
                        placeholder="Repetir clave"
                        name="confirmPassword"
                      />
                      <div>
                        {
                          errors
                          && errors.password_confirmation
                          && <span style={{ color: 'red' }}>{errors.password_confirmation.join(', ')}</span>
                        }
                      </div>
                    </Form.Field>
                    <Form.Field required style={{ textAlign: 'left' }}>
                      <label>Código</label>
                      <Input
                        fluid
                        onChange={(e) => setCode(e.target.value)}
                        placeholder="Codigo"
                        name="code"
                      />
                      <p>
                        <i>
                          Si no recibiste ningún código, volvé a solicitarlo
                          {' '}
                          <a href="/solicitar-cambio">
                          acá
                          </a>
                        </i>
                      </p>
                    </Form.Field>
                    <Button color="teal" fluid size="large" disabled={disabled}>
                      Actualizar clave
                    </Button>
                  </Segment>
                </Form>
                {
                  hasError
                  && (
                    <Toast
                      negative
                      icon={<Icon color="red" name="x" />}
                      onDismiss={() => dismissMessage()}
                      content={error}
                    />
                  )
                }
              </>
            )
            : (
              <Header as="h2">
                <Image src={prolabLogo} />
                <br />
                Se cambió tu clave correctamente. Ya podés usar tu nueva clave para iniciar sesión
                {' '}
                <a href="/iniciar-sesion-postulante">iniciar sesion</a>
              </Header>
            )
        }
      </Grid.Column>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  error: state.email.error,
  errors: state.email.errors,
  hasError: state.email.hasError,
  success: state.email.success,
});

function mapDispatchToProps(dispatch) {
  return ({
    actions: bindActionCreators({
      ...emailActions,
    }, dispatch),
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordRequest);
