import React, { useState } from 'react';
import {
  Grid,
  Segment,
  Icon,
  Header,
  Button,
  Divider,
  Popup,
  Modal,
} from 'semantic-ui-react';
import moment from 'moment';

const DeleteModal = ({
  setOpen,
  name,
  open,
}) => (
  <Modal open={open} basic size="small">
    <Header icon="archive" content={`Eliminar ${name}`} />
    <Modal.Content>
      <p>
        ¿Estás seguro/a de querer eliminarlo?
      </p>
    </Modal.Content>
    <Modal.Actions>
      <Button basic color="red" inverted onClick={setOpen({ set: false })}>
        <Icon name="remove" />
        No
      </Button>
      <Button color="green" inverted onClick={setOpen({ set: false, del: true })}>
        <Icon name="checkmark" />
        Si
      </Button>
    </Modal.Actions>
  </Modal>
);

export default ({
  onChange,
  onSubmit,
  file,
  files,
  remove,
  download,
}) => {
  const [id, setId] = useState('');
  const [name, setName] = useState('');
  const [open, setOpen] = useState(false);

  const setModal = ({ set, currentFile, del = false }) => () => {
    if (set) {
      setName(currentFile.name);
      setId(currentFile.id);
      setOpen(true);
    } else if (del) {
      remove(id);
      setOpen(false);
    } else {
      setName('');
      setId('');
      setOpen(false);
    }
  };
  moment.locale('es');
  return (
    <>
      <Segment style={{ textAlign: 'center' }} raised color="blue">
        <Grid divided>
          <Grid.Row>
            <Grid.Column>
              <Header as="h3" textAlign="left">
                <Icon name="file" />
                Archivos
              </Header>
              <Divider section />
            </Grid.Column>
          </Grid.Row>
          {files.map((f, i) => (
            <Grid.Row key={i}>
              <Grid.Column width={8}>
                <Header as="h3" textAlign="left">
                  <Icon name="file pdf outline" />
                  {f.name}
                </Header>
              </Grid.Column>
              <Grid.Column width={3}>
                <Header as="h3" textAlign="left" color="grey">
                  {moment(f.date).format('L')}
                </Header>
              </Grid.Column>
              <Grid.Column width={5}>
                <Popup
                  content="Eliminar archivo"
                  trigger={(
                    <Button
                      icon="x"
                      type="button"
                      color="red"
                      onClick={setModal({ set: true, currentFile: f })}
                    />
                  )}
                />
                <Popup
                  content="Descargar archivo"
                  trigger={(
                    <Button
                      icon="download"
                      type="button"
                      color="blue"
                      onClick={download(f.id, f.name)}
                    />
                  )}
                />
              </Grid.Column>
            </Grid.Row>
          ))}
        </Grid>
        <div style={{ marginTop: '1em' }}>
          <input
            type="file"
            name="newPhoto"
            accept=".pdf, .doc, .txt"
            onChange={onChange}
            multiple
          />
          <div style={{ textAlign: 'right', marginTop: '1em' }}>
            <Button
              type="button"
              disabled={file.length === 0}
              onClick={onSubmit}
              primary
            >
              Actualizar archivos
            </Button>
          </div>
        </div>
      </Segment>
      { open && (
        <DeleteModal open={open} setOpen={setModal} name={name} />
      )}
    </>
  );
};
