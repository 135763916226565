import React from 'react';
import Recaptcha from 'react-recaptcha';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import * as applicantActions from 'frontend/actions/login-applicant';

const { CAPTCHA_KEY } = process.env;

class Captcha extends React.Component {
  constructor(props) {
    super(props);
    this.handleExpired = this.handleExpired.bind(this);
    this.handleVerify = this.handleVerify.bind(this);
  }

  handleExpired() {
    const {
      actions: {
        captchaValidUpdate,
      },
    } = this.props;
    captchaValidUpdate(false);
  }

  handleVerify(response) {
    const {
      actions: {
        captchaValidUpdate,
      },
    } = this.props;
    if (response) {
      captchaValidUpdate(true);
    }
  }

  render() {
    return (
      <div style={{ margin: '1em' }}>
        <Recaptcha
          sitekey={CAPTCHA_KEY}
          render="explicit"
          expiredCallback={this.handleExpired}
          verifyCallback={this.handleVerify}
        />
      </div>
    );
  }
}
const mapStateToProps = (state) => ({
  loginApplicant: state.loginApplicant,
});

function mapDispatchToProps(dispatch) {
  return ({
    actions: bindActionCreators({
      ...applicantActions,
    }, dispatch),
  });
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(Captcha));

