export default {
  countries: [],
  documentTypes: [],
  provinces: [],
  gemProvinces: {},
  parties: {},
  cities: {},
  professionalDevAreas: [],
  genders: [],
  iGenders: [],
  maritalStatuses: [],
  achievedStudies: [],
  careers: [],
  institutions: [],
  degrees: [],
  jobCategories: [],
  jobCategoriesGroup: [],
  representatives: [],
  isFetchingData: false,
  errors: '',
};
