import React from 'react';
import { Field } from 'redux-form';
import { Form } from 'semantic-ui-react';
import { RenderSelect, RenderInput } from 'frontend/components/Form';
import onErrors from 'helpers/errors';

const LocationForm = (props) => {
  const {
    countryLabel,
    countryOptions,
    countryErrors,
    countryName,
    provinceLabel,
    provinceOptions,
    provinceName,
    partyLabel,
    partyOptions,
    partyName,
    cityLabel,
    cityOptions,
    cityName,
    cityErrors,
    cityTextName,
    cityTextLabel,
    cityTextErrors,
    provinceGemOptions,
    useCityId,
    required,
    onCountryChange,
    onProvinceChange,
    onPartyChange,
  } = props;
  return (
    <>
      <Form.Group widths="equal">
        <Field
          component={RenderSelect}
          options={countryOptions}
          error={onErrors(countryErrors)}
          name={countryName}
          fluid
          label={countryLabel}
          placeholder={countryLabel}
          required={required}
          onChange={onCountryChange}
        />
        { useCityId
          && (
            <>
              <Field
                component={RenderSelect}
                name={provinceName}
                fluid
                onChange={onProvinceChange}
                label={provinceLabel}
                placeholder={provinceLabel}
                options={provinceOptions}
                required={required}
              />
              <Field
                component={RenderSelect}
                name={partyName}
                fluid
                onChange={onPartyChange}
                label={partyLabel}
                options={partyOptions}
                placeholder={partyLabel}
                required={required}
              />
              <Field
                component={RenderSelect}
                error={onErrors(cityErrors)}
                name={cityName}
                options={cityOptions}
                fluid
                label={cityLabel}
                placeholder={cityLabel}
                required={required}
              />
            </>
          )}
        { !useCityId
          && (
            <>
              <Field
                component={RenderSelect}
                name={provinceName}
                fluid
                label={provinceLabel}
                placeholder={provinceLabel}
                options={provinceGemOptions}
                required={required}
              />
              <Field
                component={RenderInput}
                error={onErrors(cityTextErrors)}
                name={cityTextName}
                fluid
                label={cityTextLabel}
                placeholder={cityTextLabel}
                required={required}
              />
            </>
          )}
      </Form.Group>
    </>
  );
};

export default LocationForm;
