import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as curriculumActions from 'frontend/actions/curriculum';
import { FileUploadForm } from 'frontend/components/CurriculumForm/partials';
import { Toast } from 'frontend/components/Form';
import { Icon } from 'semantic-ui-react';
import axios from 'helpers/axios';

const handleDismiss = (dismissAction, message) => () => dismissAction(message);

const usePreinitializelFileUpload = (actions) => {
  const {
    getCVFiles,
  } = actions;

  useEffect(() => {
    getCVFiles();
  }, [
    getCVFiles,
  ]);
};

const download = (id, name) => () => {
  axios({
    url: `/api/v1/curriculums/${id}/download_file`,
    method: 'get',
    responseType: 'blob',
  }).then((response) => response.data)
    .then((blob) => {
      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = name;
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      link.remove();
    });
};

const handleSubmit = (files, submit, setFile) => () => {
  if (files.length === 0) {
    return;
  }

  const formData = new FormData();
  for (let i = 0; i < files.length; i += 1) {
    formData.append('files[]', files[i]);
  }
  submit(formData);
  setFile('');
};

const FileUpload = ({
  cvFiles,
  fileErrors,
  hasFileErrors,
  savedFile,
  deletedFile,
  actions,
  ...props
}) => {
  usePreinitializelFileUpload(actions);
  const [file, setFile] = useState('');
  const handleChange = (e) => {
    setFile(e.target.files);
  };
  const remove = (id) => (actions.removeFile(id));
  return (
    <>
      {
        deletedFile
        && (
          <Toast
            positive
            onDismiss={handleDismiss(actions.dismissMessage, 'deletedFile')}
            icon={<Icon color="green" name="check" />}
            header="Se eliminó el archivo corrctamente"
          />
        )
      }
      {
        hasFileErrors
        && (
          <Toast
            negative
            onDismiss={handleDismiss(actions.dismissMessage, 'hasFileErrors')}
            icon={<Icon color="red" name="x" />}
            header="Ocurrió un error en el sistema, intentá mas tarde"
            content={fileErrors}
          />
        )
      }
      {
        savedFile
        && (
          <Toast
            positive
            onDismiss={handleDismiss(actions.dismissMessage, 'savedFile')}
            icon={<Icon color="green" name="check" />}
            header="Se guardaron el/los archivo/s correctamente"
          />
        )
      }

      <FileUploadForm
        onChange={handleChange}
        file={file}
        remove={remove}
        files={cvFiles}
        download={download}
        onSubmit={handleSubmit(file, actions.uploadFiles, setFile)}
        {...props}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  cvFiles: state.curriculum.cvFiles,
  hasFileErrors: state.curriculum.hasFileErrors,
  fileErrors: state.curriculum.fileErrors,
  savedFile: state.curriculum.savedFile,
  deletedFile: state.curriculum.deletedFile,
});

function mapDispatchToProps(dispatch) {
  return ({
    actions: bindActionCreators({
      ...curriculumActions,
    }, dispatch),
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(FileUpload);
