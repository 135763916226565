import {
  CURRICULUM_GET_FAILURE,
  CURRICULUM_GET_REQUEST,
  CURRICULUM_GET_SUCCESS,

  CURRICULUM_UPDATE_FAILURE,
  CURRICULUM_UPDATE_REQUEST,
  CURRICULUM_UPDATE_SUCCESS,

  CURRICULUM_FILES_POST_REQUEST,
  CURRICULUM_FILES_POST_SUCCESS,
  CURRICULUM_FILES_POST_FAILURE,

  CURRICULUM_FILES_REMOVE_REQUEST,
  CURRICULUM_FILES_REMOVE_SUCCESS,
  CURRICULUM_FILES_REMOVE_FAILURE,

  CURRICULUM_FILES_GET_FAILURE,
  CURRICULUM_FILES_GET_SUCCESS,
  CURRICULUM_FILES_GET_REQUEST,

  ON_DISMISS_MESSAGE,
  ON_REMOVE_ERROR,
} from 'frontend/constants/curriculum';
import initialState from 'frontend/state/curriculum';

const mapArray = (arr) => (
  arr.map((el) => {
    const newElement = el;
    if (el.institution_id === null) {
      newElement.institution_id = 'other';
    }
    if (el.degree_id === null) {
      newElement.degree_id = 'other';
    }
    newElement.completion = parseFloat(el.completion, 10);
    return newElement;
  })
);

export default (state = initialState, action) => {
  switch (action.type) {
    case CURRICULUM_GET_REQUEST: {
      return {
        ...state,
        isFetching: true,
        httpError: false,
      };
    }
    case CURRICULUM_GET_SUCCESS: {
      const { payload } = action;
      let newState = {
        ...state,
        data: {
          ...payload,
        },
        isFetching: false,
        errors: initialState.errors,
      };

      if (payload.formal_studies_attributes.length > 0) {
        newState = {
          ...newState,
          data: {
            ...newState.data,
            formal_studies_attributes: mapArray(newState.data.formal_studies_attributes),
          },
        };
      }

      return newState;
    }
    case CURRICULUM_GET_FAILURE: {
      console.log(action.payload);
      return {
        ...state,
        httpError: true,
        isFetching: false,
      };
    }

    // Update curriculum

    case CURRICULUM_UPDATE_REQUEST: {
      return {
        ...state,
        isFetching: true,
        success: false,
        hasErrors: false,
      };
    }
    case CURRICULUM_UPDATE_SUCCESS: {
      const { payload } = action;

      let newState = {
        ...state,
        data: {
          ...payload,
        },
        errors: initialState.errors,
        success: true,
        isFetching: false,
      };

      if (payload.formal_studies_attributes.length > 0) {
        newState = {
          ...newState,
          data: {
            ...newState.data,
            formal_studies_attributes: mapArray(newState.data.formal_studies_attributes),
          },
        };
      }
      return newState;
    }
    case CURRICULUM_UPDATE_FAILURE: {
      return {
        ...state,
        errors: action.payload.errors,
        success: false,
        hasErrors: true,
        isFetching: false,
      };
    }

    /* get files */
    case CURRICULUM_FILES_REMOVE_REQUEST:
    case CURRICULUM_FILES_POST_REQUEST:
    case CURRICULUM_FILES_GET_REQUEST: {
      return {
        ...state,
        isFetching: true,
        error: false,
        savedFile: false,
        deletedFile: false,
      };
    }
    case CURRICULUM_FILES_GET_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        cvFiles: action.payload,
      };
    }
    case CURRICULUM_FILES_GET_FAILURE: {
      console.log(action.payload);
      return {
        ...state,
        isFetching: false,
        httpError: true,
      };
    }
    /* post files */
    case CURRICULUM_FILES_REMOVE_FAILURE:
    case CURRICULUM_FILES_POST_FAILURE: {
      const {
        code,
        data,
      } = action.payload;
      if (code === 500) {
        return {
          ...state,
          isFetching: false,
          httpError: true,
        };
      }
      return {
        ...state,
        isFetching: false,
        hasFileErrors: true,
        fileError: data,
      };
    }
    case CURRICULUM_FILES_REMOVE_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        cvFiles: action.payload,
        deletedFile: true,
      };
    }
    case CURRICULUM_FILES_POST_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        cvFiles: action.payload,
        savedFile: true,
      };
    }
    /* dismiss messages */
    case ON_DISMISS_MESSAGE: {
      const {
        message,
      } = action.payload;
      return {
        ...state,
        [message]: false,
      };
    }
    /* remove errors when an element of an array is removed */
    case ON_REMOVE_ERROR: {
      const newState = state;
      const {
        key,
        index,
      } = action.payload;
      if ((state.errors.work_studies[key].length + 1) > index) {
        newState.errors.work_studies[key].splice(index, 1);
      }
      return newState;
    }
    default:
      return state;
  }
};
