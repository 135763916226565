import React from 'react';
import { Form } from 'semantic-ui-react';

const RenderTextArea = (field) => (
  <Form.Field
    width={field.width}
    inline={field.inline}
    required={field.required}
    disabled={field.disabled}
    error={Boolean(field.error)}
  >
    <Form.TextArea
      rows={field.rows}
      fluid={field.fluid.toString()}
      {...field.input}
      name={field.input.name}
      value={field.input.value}
      required={field.required}
      label={field.label}
      placeholder={field.placeholder}
    />
    <div>{field.error && <span style={{ color: 'red' }}>{field.error}</span>}</div>
  </Form.Field>
);

export default RenderTextArea;
