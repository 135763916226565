import React, { useState } from 'react';
import {
  Button,
  Grid,
  Image,
  Segment,
  Form,
  Header,
  Input,
  Icon,
} from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { Toast } from 'frontend/components/Form';
import prolabLogo from 'images/prolab-logo';
import * as emailActions from 'frontend/actions/email';
import { connect } from 'react-redux';

const ChangePasswordRequest = (props) => {
  const {
    actions: {
      requestPasswordChange,
      dismissMessage,
    },
    hasError,
    error,
    success,
  } = props;
  const [email, setEmail] = useState('');
  const style = success ? { maxWidth: 700 } : { maxWidth: 450 };

  return (
    <Grid textAlign="center" verticalAlign="middle">
      <Grid.Column style={style}>
        {
          !success
            ? (
              <>
                <Header as="h2" color="teal">
                  <Image src={prolabLogo} />
                  <br />
                  Ingresa un correo electrónico
                </Header>
                <Form size="large" onSubmit={() => requestPasswordChange(email)}>
                  <Segment stacked>
                    <Form.Field required style={{ textAlign: 'left' }}>
                      <label>Correo electrónico</label>
                      <Input
                        type="email"
                        fluid
                        onChange={(e) => setEmail(e.target.value)}
                        icon="envelope"
                        iconPosition="left"
                        placeholder="Correo electrónico"
                        name="email"
                      />
                    </Form.Field>
                    <Button color="teal" fluid size="large">
                      Enviar correo
                    </Button>
                  </Segment>
                </Form>
                {
                  hasError
                  && (
                    <Toast
                      negative
                      icon={<Icon color="red" name="x" />}
                      onDismiss={() => dismissMessage()}
                      content={error}
                    />
                  )
                }
              </>
            )
            : (
              <Header as="h2">
                <Image src={prolabLogo} />
                <br />
                Se envió un correo a
                {' '}
                {email}
                {' '}
                con las instrucciones para recuperar tu clave.
                No olvides revisar tu carpeta de correo no deseado en
                caso que no te llegue en los próximos minutos.
              </Header>
            )
        }
      </Grid.Column>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  error: state.email.error,
  hasError: state.email.hasError,
  success: state.email.success,
});

function mapDispatchToProps(dispatch) {
  return ({
    actions: bindActionCreators({
      ...emailActions,
    }, dispatch),
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ChangePasswordRequest);
