import { connect } from 'react-redux';
import { reduxForm, formValueSelector } from 'redux-form';
import RegisterApplicant from './ApplicantForm';

const selector = formValueSelector('registerApplicantForm');

const RegisterApplicantContainer = reduxForm({
  form: 'registerApplicantForm',
})(RegisterApplicant);

export default connect(
  (state) => {
    const selectedAchievedStudy = selector(state, 'profile.achieved_study_id');
    return {
      initialValues: state.applicant,
      selectedAchievedStudy,
    };
  },
)(RegisterApplicantContainer);
