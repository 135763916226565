import { reduxForm, formValueSelector } from 'redux-form';
import { connect } from 'react-redux';
import { ProfileForm } from './ProfileForm';

const selector = formValueSelector('profileForm');

const ProfileFormContainer = reduxForm({
  form: 'profileForm',
})(ProfileForm);

export default connect(
  (state) => {
    const selectedAchievedStudy = selector(state, 'profile.achieved_study_id');
    return {
      enableReinitialize: state.applicant.enableReinitialize,
      initialValues: state.applicant,
      selectedAchievedStudy,
    };
  },
)(ProfileFormContainer);
