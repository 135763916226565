import React from 'react';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';

// Componentes
import Layout from 'containers/Layout';
import NotFound from 'frontend/components/ErrorCodes/404';
import { PublicJobOffers, JobOffer } from 'containers/PublicJobOffers';
import RegisterApplicant from 'containers/RegisterApplicant/RegisterApplicant';
import LoginApplicant from 'containers/Login/LoginApplicant';
import CompleteProfile from 'containers/Applicant/CompleteProfile';
import Curriculum from 'containers/Curriculum';
import EditProfile from 'containers/Applicant/EditProfile';
import { ChangePasswordRequest, UpdatePassword } from 'containers/ChangePassword';
import ResendEmail from 'containers/ResendEmail';

const closeSession = () => {
  window.location = '/api/v1/sessions/close';
  return null;
};

function AppRouter() {
  const mustBeLocal = true;
  const mustBeSso = true;
  const permittedForAll = true;
  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Layout(PublicJobOffers, { permittedForAll, showOffersTitle: true })} />
        <Route exact path="/iniciar-sesion-postulante" component={Layout(LoginApplicant)} />
        <Route exact path="/solicitud-cambio" component={Layout(ChangePasswordRequest)} />
        <Route exact path="/cambiar-clave" component={Layout(UpdatePassword)} />
        <Route exact path="/registrar-postulante" component={Layout(RegisterApplicant)} />
        <Route exact path="/cerrar-sesion" component={closeSession} />
        <Route exact path="/completar-perfil" component={Layout(CompleteProfile, { mustBeSso })} />
        <Route exact path="/reenviar-confirmacion" component={Layout(ResendEmail)} />
        <Route exact path="/curriculum" component={Layout(Curriculum, { mustBeLocal, mustBeSso })} />
        <Route exact path="/perfil" component={Layout(EditProfile, { mustBeLocal, mustBeSso })} />
        <Route exact path="/busquedas" component={Layout(PublicJobOffers, { permittedForAll, showOffersTitle: true })} />
        <Route exact path="/busquedas/:id" component={Layout(JobOffer, { permittedForAll, showOffersTitle: true })} />
        <Route exact path="/administracion" />
        <Route path="*" component={Layout(NotFound, { permittedForAll })} />
      </Switch>
    </Router>
  );
}

export default AppRouter;
