import React from 'react';
import {
  Container,
  Header,
  Icon,
} from 'semantic-ui-react';

export default () => (
  <Container textAlign="center" style={{}}>
    <Header as="h1">
      <Icon name="warning circle" />
      PÁGINA NO ENCONTRADA
    </Header>
    <Header as="h2">
      Lo sentimos pero la página que ha solicitado no se encuentra disponible, o se ha movido.
      <br />
      Por favor intente más tarde o vaya a nuestra página de
      {' '}
      <a href="/">inicio</a>
    </Header>
  </Container>
);
