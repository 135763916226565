import React from 'react';
import { Input, Button, Popup } from 'semantic-ui-react';

const PublicJobOfferCard = ({ onChange, onSubmit }) => (
  <div className="public-job-offers-filter">
    <div>
      <label>Cargo</label>
      <br />
      <Input onChange={onChange} placeholder="Cargo" name="position_title" />
    </div>
    <Popup
      content="Filtrar por cargo buscado"
      trigger={(
        <Button
          icon="search"
          type="button"
          color="blue"
          onClick={onSubmit}
        />
      )}
    />
  </div>
);

export default PublicJobOfferCard;
