import React from 'react';

const footer = () => {
  const { version } = document.body.dataset;
  return (
    <footer className="footer" height="3rem">
      <div>
        &copy;
        <a href="https://cespi.unlp.edu.ar">CeSPI</a>
        &middot;
        <a href="https://unlp.edu.ar">Universidad Nacional de La Plata</a>
        &middot;
        2019 &ndash; 2020
        &middot;
        Todos los derechos reservados conforme a la ley 11.723.
      </div>
      <div>
        UNLP Presidencia: Avda. 7 N°776 (1900), La Plata, Buenos Aires, Argentina. Versión:
        &nbsp;
        { version }
        .
      </div>
    </footer>
  );
};

export default footer;
