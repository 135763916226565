import React, { useEffect, useState } from 'react';
import { Icon } from 'semantic-ui-react';
import { reduxForm } from 'redux-form';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';

import { Toast } from 'frontend/components/Form';
import CurriculumForm from 'frontend/components/CurriculumForm';
import * as curriculumActions from 'frontend/actions/curriculum';

const CurriculumFormContainer = connect(
  (state) => ({
    enableReinitialize: true,
    initialValues: state.curriculum.data,
  }),
)(reduxForm({ form: 'curriculumForm' })(CurriculumForm));

const handleDismiss = (dismissAction, message) => () => dismissAction(message);

const handleCurriculumSubmit = (updateAction) => (data) => (updateAction(data));

const tabHasErrors = (array) => (array.some((e) => Object.keys(e).length > 0));

const usePreinitializeCurriculum = (actions) => {
  const {
    getCurriculumFromLoggedApplicant,
  } = actions;
  useEffect(() => {
    getCurriculumFromLoggedApplicant();
  }, [
    getCurriculumFromLoggedApplicant,
  ]);
};

const Curriculum = ({
  isFetching,
  hasErrors,
  success,
  errors,
  httpError,
  currentUser,
  actions: {
    updateLoggedApplicantCurriculum,
    dismissMessage,
    removeError,
    ...rest
  },
  location: {
    search,
  },
  ...props
}) => {
  if (currentUser.role === 'admin') {
    window.location = '/';
  }

  const [activeItem, setActiveItem] = useState('Objetivos laborales');
  const handleItemClick = (_, { name }) => setActiveItem(name);
  usePreinitializeCurriculum(rest);

  // si el parametro jobOffer se encuentra, quiere decir que fue redireccionado desde una oferta
  // por no tener el CV completo. en ese caso se agrega el boton para volver
  // a la oferta laboral de la que fue redireccionado el postulante

  const [mustComplete, setMustComplete] = useState(false);
  const [jobofferReturnId, setJobofferReturnId] = useState(undefined);
  const [alreadyChecked, setAlreadyChecked] = useState(false);

  if (search.includes('jobOffer') && !alreadyChecked) {
    const id = Number(search.split('=')[1]);

    setAlreadyChecked(true);
    setJobofferReturnId(id);

    if (Number.isInteger(id) && (id > 0)) {
      setMustComplete(true);
    } else setJobofferReturnId(undefined);
  }

  return (
    <>
      {
        mustComplete
        && (
          <Toast
            info
            onDismiss={() => setMustComplete(false)}
            icon={<Icon name="address card" />}
            header="Tenés que completar tu CV para poder postularte a la búsqueda laboral. Una vez que lo completes podés volver a intentarlo."
          />
        )
      }
      {
        httpError
        && (
          <Toast
            negative
            onDismiss={handleDismiss(dismissMessage, 'httpError')}
            icon={<Icon color="red" name="x" />}
            header="Ocurrió un error en el sistema, intenta mas tarde"
          />
        )
      }
      {
        success
        && (
          <Toast
            success
            onDismiss={handleDismiss(dismissMessage, 'success')}
            icon={<Icon color="green" name="check" />}
            header="Tu CV ha sido actualizado"
          />
        )
      }
      {
        hasErrors
        && (
          <Toast
            negative
            onDismiss={handleDismiss(dismissMessage, 'hasErrors')}
            icon={<Icon color="red" name="x" />}
            header="Ha ocurrido un error"
            content="Ténes errores en el CV"
          />
        )
      }
      <CurriculumFormContainer
        {...props}
        tabHasErrors={tabHasErrors}
        isFetching={isFetching}
        activeItem={activeItem}
        jobofferReturnId={jobofferReturnId}
        handleItemClick={handleItemClick}
        submitCallback={handleCurriculumSubmit(updateLoggedApplicantCurriculum)}
        removeError={removeError}
        errors={errors}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  success: state.curriculum.success,
  hasErrors: state.curriculum.hasErrors,
  isFetching: state.curriculum.isFetching,
  errors: state.curriculum.errors,
  httpError: state.curriculum.httpError,
});

function mapDispatchToProps(dispatch) {
  return ({
    actions: bindActionCreators({
      ...curriculumActions,
    }, dispatch),
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(Curriculum);
