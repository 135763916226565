import React from 'react';
import {
  Divider,
  Form,
  Segment,
  Container,
  Header,
  Button,
  Dimmer,
  Loader,
} from 'semantic-ui-react';
import { Field } from 'redux-form';
import { RenderInput } from 'frontend/components/Form';
import { Profile } from 'frontend/components/ApplicantProfile/ProfileForm';

import onErrors from 'helpers/errors';

const ApplicantForm = (props) => {
  const {
    onSubmit,
    fetching,
    handleSubmit,
    errors,
  } = props;
  return (
    <div>
      <Container className="form-container">
        <Dimmer active={fetching}>
          <Loader> Cargando</Loader>
        </Dimmer>
        <Header as="h2" textAlign="left">
          Perfil
        </Header>
        <Divider horizontal />
        <Form onSubmit={handleSubmit(onSubmit)}>
          <Segment raised color="blue">
            <Form.Group widths="equal">
              <Field
                component={RenderInput}
                error={onErrors(errors.username)}
                name="username"
                fluid
                disabled
                label="Usuario"
                placeholder="Usuario"
                required
              />
              <Field
                component={RenderInput}
                error={onErrors(errors.password)}
                name="password"
                fluid
                label="Clave"
                type="password"
                placeholder="Clave"
                popupText="Solo completar este campo si se desea modificar la clave"
              />
              <Field
                component={RenderInput}
                error={onErrors(errors.password_confirmation)}
                name="password_confirmation"
                fluid
                label="Confirmar clave"
                type="password"
                placeholder="Confirmar clave"
              />
            </Form.Group>
          </Segment>
          <Profile {...props} onErrors={onErrors} />
          <Button primary fluid>Actualizar perfil</Button>
        </Form>
      </Container>
    </div>
  );
};

export default ApplicantForm;
