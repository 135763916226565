import React from 'react';
import ReactDOM from 'react-dom';
import 'semantic-ui-css/semantic.min.css';
import 'styles/frontend.scss';
import { Provider } from 'react-redux';
import configureStore from 'frontend/store';
import AppRouter from 'frontend/routes';

const store = configureStore();

document.addEventListener('DOMContentLoaded', () => {
  ReactDOM.render(
    <Provider store={store}>
      <AppRouter />
    </Provider>,
    document.getElementById('frontend-root'),
  );
});
