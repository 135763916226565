import React from 'react';
import { Divider, Form, Segment } from 'semantic-ui-react';
import { Field } from 'redux-form';
import LocationForm from 'frontend/containers/Location/Location';
import { RenderSelect, RenderInput, RenderDate } from 'frontend/components/Form';

const personalInfo = (props) => {
  const {
    countries,
    maritalStatuses,
    genders,
    documentTypes,
    errors,
    iGenders,
    onErrors,
  } = props;
  return (
    <Segment raised color="blue">
      <Divider horizontal> Datos personales </Divider>
      <Form.Group widths="equal">
        <Field
          component={RenderInput}
          error={onErrors(errors['profile.surname'])}
          name="profile.surname"
          fluid
          label="Apellido"
          placeholder="Apellido"
          required
        />
        <Field
          component={RenderInput}
          error={onErrors(errors['profile.name'])}
          name="profile.name"
          fluid
          label="Nombre"
          placeholder="Nombre"
          required
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Field
          component={RenderSelect}
          error={onErrors(errors['profile.i_gender_id'])}
          name="profile.i_gender_id"
          fluid
          label="Sexo (como figura en el documento)"
          placeholder="Sexo (como figura en el documento)"
          options={iGenders}
          required
        />
        <Field
          component={RenderSelect}
          error={onErrors(errors['profile.self_perceived_gender'])}
          name="profile.self_perceived_gender_id"
          fluid
          label="Género autopercibido"
          placeholder="Género autopercibido"
          options={genders}
          required
        />
        <Field
          component={RenderSelect}
          error={onErrors(errors['profile.i_document_type_id'])}
          name="profile.i_document_type_id"
          fluid
          label="Tipo de documento"
          placeholder="Tipo de documento"
          options={documentTypes}
          required
        />
        <Field
          component={RenderInput}
          error={onErrors(errors['profile.document_number'])}
          name="profile.document_number"
          fluid
          label="Número de documento"
          placeholder="Número de documento"
          required
        />
      </Form.Group>
      <Form.Group widths="equal">
        <Field
          component={RenderInput}
          error={onErrors(errors['profile.children'])}
          name="profile.children"
          fluid
          label="Cantidad de hijos"
          type="number"
          placeholder="Cantidad de hijos"
          required
        />
        <Field
          component={RenderSelect}
          error={onErrors(errors['profile.marital_status'])}
          name="profile.marital_status_id"
          fluid
          label="Estado civil"
          placeholder="Estado civil"
          options={maritalStatuses}
          required
        />
        <Field
          component={RenderDate}
          error={onErrors(errors['profile.birth_date'])}
          options={countries}
          name="profile.birth_date"
          label="Fecha de nacimiento"
          placeholder="Fecha de nacimiento"
          required
        />
      </Form.Group>
      <Divider horizontal> Lugar de nacimiento </Divider>
      <LocationForm
        countryName="profile.birth_country_code"
        countryErrors={errors['profile.birth_country_code']}
        provinceName="province2"
        countryLabel="Pais"
        provinceLabel="Provincia"
        partyLabel="Partido"
        partyName="party2"
        cityLabel="Ciudad"
        cityTextLabel="Ciudad"
        cityName="profile.birth_city_id"
        cityTextName="profile.birth_city_name"
        cityErrors={errors['profile.birth_city']}
        cityTextErrors={errors['profile.birth_city_name']}
        required={false}
      />
    </Segment>
  );
};

export default personalInfo;
