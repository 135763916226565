import axios from 'axios';

export const csrfToken = (document) => (
  document.querySelector('[name="csrf-token"]').content
);

const token = document.querySelector('[name="csrf-token"]').content;

axios.defaults.headers.common['X-CSRF-TOKEN'] = token;

export default axios;
