export default {
  public_job_offers: [],
  total_elements: 0,
  total_pages: 1,
  public_job_offer: {
    id: null,
    start_date: null,
    incorporation_date: null,
    position_title: '',
    position_description: '',
    expected_age: '',
    expected_gender: {
      id: null,
      name: '',
    },
    responsibilities: '',
    expected_abilities: '',
    expected_job_experience: '',
    expected_profile: '',
    expected_studies: '',
    residence_area: '',
    travelling_availability: false,
    people_in_charge: false,
    expected_own_mobility: false,
    area: null,
    sector: null,
    label: null,
    public_salary_description: '',
    work_schedule: '',
    location: '',
    contract_type: '',
    career_plan: false,
    training_plan: false,
    ad_text: '',
    active_user_has_applied: undefined,
  },
  isFetching: false,
  errors: '',
};
