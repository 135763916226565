import * as Const from 'constants/reference';

import {
  fetchFromAPI,
  asOptionsForSelect,
  achiviedStudiesOptions,
  jobCategoriesMappedOptions,
} from 'helpers/reference';

export const getCountries = () => (dispatch) => {
  dispatch({ type: Const.COUNTRY_GET_REQUEST });
  return fetchFromAPI('countries').then((res) => {
    dispatch({
      type: Const.COUNTRY_GET_SUCCESS,
      payload: {
        name: 'countries',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => dispatch({
    type: Const.COUNTRY_GET_FAILURE,
    payload: {
      error: err.response,
    },
  }));
};

export const getGemProvinces = (country) => (dispatch) => {
  dispatch({ type: Const.GEM_PROVINCE_GET_REQUEST });
  return fetchFromAPI('gem_provinces', { c: country }).then((res) => {
    dispatch({
      type: Const.GEM_PROVINCE_GET_SUCCESS,
      payload: {
        pos: country,
        name: 'gemProvinces',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => dispatch({
    type: Const.GEM_PROVINCE_GET_FAILURE,
    payload: {
      error: err.response,
    },
  }));
};

export const getDocumentTypes = () => (dispatch) => {
  dispatch({ type: Const.DOCUMENT_TYPE_GET_REQUEST });
  return fetchFromAPI('document_types').then((res) => {
    dispatch({
      type: Const.DOCUMENT_TYPE_GET_SUCCESS,
      payload: {
        name: 'documentTypes',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => dispatch({
    type: Const.DOCUMENT_TYPE_GET_FAILURE,
    payload: {
      error: err.response,
    },
  }));
};

export const getGenders = () => (dispatch) => {
  dispatch({ type: Const.GENDER_GET_REQUEST });
  return fetchFromAPI('genders').then((res) => {
    dispatch({
      type: Const.GENDER_GET_SUCCESS,
      payload: {
        name: 'genders',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => dispatch({
    type: Const.GENDER_GET_FAILURE,
    payload: {
      error: err.response,
    },
  }));
};

export const getIntegratorGenders = () => (dispatch) => {
  dispatch({ type: Const.INTEGRATOR_GENDER_GET_REQUEST });
  return fetchFromAPI('integrator_genders').then((res) => {
    dispatch({
      type: Const.INTEGRATOR_GENDER_GET_SUCCESS,
      payload: {
        name: 'iGenders',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => dispatch({
    type: Const.INTEGRATOR_GENDER_GET_FAILURE,
    payload: {
      error: err.response,
    },
  }));
};

export const getMaritalStatuses = () => (dispatch) => {
  dispatch({ type: Const.MARITAL_STATUS_GET_REQUEST });
  return fetchFromAPI('marital_statuses').then((res) => {
    dispatch({
      type: Const.MARITAL_STATUS_GET_SUCCESS,
      payload: {
        name: 'maritalStatuses',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => dispatch({
    type: Const.MARITAL_STATUS_GET_FAILURE,
    payload: {
      error: err.response,
    },
  }));
};

export const getAchievedStudies = () => (dispatch) => {
  dispatch({ type: Const.ACHIEVED_STUDY_GET_REQUEST });
  return fetchFromAPI('achieved_studies').then((res) => {
    dispatch({
      type: Const.ACHIEVED_STUDY_GET_SUCCESS,
      payload: {
        name: 'achievedStudies',
        data: achiviedStudiesOptions(res.data),
      },
    });
  }).catch((err) => dispatch({
    type: Const.ACHIEVED_STUDY_GET_FAILURE,
    payload: {
      error: err.response,
    },
  }));
};

export const getCareers = () => (dispatch) => {
  dispatch({ type: Const.CAREER_GET_REQUEST });
  return fetchFromAPI('careers').then((res) => {
    dispatch({
      type: Const.CAREER_GET_SUCCESS,
      payload: {
        name: 'careers',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => {
    dispatch({ type: Const.CAREER_GET_FAILURE, payload: { error: err.response } });
  });
};

export const getProvinces = () => (dispatch) => {
  dispatch({ type: Const.PROVINCE_GET_REQUEST });
  return fetchFromAPI('provinces').then((res) => {
    dispatch({
      type: Const.PROVINCE_GET_SUCCESS,
      payload: {
        name: 'provinces',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => {
    dispatch({ type: Const.PROVINCE_GET_FAILURE, payload: { error: err.response } });
  });
};

export const getParties = (province) => (dispatch) => {
  dispatch({ type: Const.PARTY_GET_REQUEST });
  return fetchFromAPI('parties', { p: province }).then((res) => {
    dispatch({
      type: Const.PARTY_GET_SUCCESS,
      payload: {
        pos: province,
        name: 'parties',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => {
    dispatch({ type: Const.PARTY_GET_FAILURE, payload: { error: err.response } });
  });
};

export const getCities = (party) => (dispatch) => {
  dispatch({ type: Const.CITY_GET_REQUEST });
  return fetchFromAPI('cities', { p: party }).then((res) => {
    dispatch({
      type: Const.CITY_GET_SUCCESS,
      payload: {
        pos: party,
        name: 'cities',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => {
    dispatch({ type: Const.CITY_GET_FAILURE, payload: { error: err.response } });
  });
};

export const getProfessionalDevAreas = () => (dispatch) => {
  dispatch({ type: Const.PROFESSIONAL_DEV_AREA_GET_REQUEST });
  return fetchFromAPI('professional_dev_area').then((res) => {
    dispatch({
      type: Const.PROFESSIONAL_DEV_AREA_GET_SUCCESS,
      payload: {
        name: 'professionalDevAreas',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => {
    dispatch({ type: Const.PROFESSIONAL_DEV_AREA_GET_FAILURE, payload: { error: err.response } });
  });
};

export const getJobCategories = () => (dispatch) => {
  dispatch({ type: Const.JOB_CATEGORIES_GET_REQUEST });
  return fetchFromAPI('job_categories').then((res) => {
    dispatch({
      type: Const.JOB_CATEGORIES_GET_SUCCESS,
      payload: {
        name: 'jobCategories',
        data: jobCategoriesMappedOptions(res.data),
      },
    });
  }).catch((err) => {
    dispatch({ type: Const.JOB_CATEGORIES_GET_FAILURE, payload: { error: err.response } });
  });
};

export const getInstitutions = () => (dispatch) => {
  dispatch({ type: Const.INSTITUTIONS_GET_REQUEST });
  return fetchFromAPI('institutions').then((res) => {
    dispatch({
      type: Const.INSTITUTIONS_GET_SUCCESS,
      payload: {
        name: 'institutions',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => {
    dispatch({ type: Const.INSTITUTIONS_GET_FAILURE, payload: { error: err.response } });
  });
};

export const getDegrees = () => (dispatch) => {
  dispatch({ type: Const.DEGREES_GET_REQUEST });
  return fetchFromAPI('degrees').then((res) => {
    dispatch({
      type: Const.DEGREES_GET_SUCCESS,
      payload: {
        name: 'degrees',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => dispatch({
    type: Const.DEGREES_GET_FAILURE,
    payload: {
      error: err.response,
    },
  }));
};

export const getRepresentatives = () => (dispatch) => {
  dispatch({ type: Const.REPRESENTATIVES_GET_REQUEST });
  return fetchFromAPI('representatives').then((res) => {
    dispatch({
      type: Const.REPRESENTATIVES_GET_SUCCESS,
      payload: {
        name: 'representatives',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => dispatch({
    type: Const.REPRESENTATIVES_GET_FAILURE,
    payload: {
      error: err.response,
    },
  }));
};
