export const RESEND_EMAIL_REQUEST = 'resend-email-request';
export const RESEND_EMAIL_SUCCESS = 'resend-email-success';
export const RESEND_EMAIL_FAILURE = 'resend-email-failure';

export const REQUEST_PASSWORD_CHANGE_EMAIL_REQUEST = 'request-password-change-email-request';
export const REQUEST_PASSWORD_CHANGE_EMAIL_SUCCESS = 'request-password-change-email-success';
export const REQUEST_PASSWORD_CHANGE_EMAIL_FAILURE = 'request-password-change-email-failure';

export const UPDATE_PASSWORD_REQUEST = 'update-password-request';
export const UPDATE_PASSWORD_SUCCESS = 'update-password-success';
export const UPDATE_PASSWORD_FAILURE = 'update-password-failure';

export const ON_DISMISS_MESSAGE = 'dismiss-message';
