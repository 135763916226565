import React from 'react';
import {
  RenderInput,
  RenderTextArea,
} from 'frontend/components/Form';
import {
  Grid,
  Segment,
  Divider,
  Button,
  Icon,
  Header,
} from 'semantic-ui-react';
import { remove, get } from 'helpers/reduxFormArray';
import { Field, FieldArray } from 'redux-form';

const SocialNetwork = (props) => {
  const {
    keyName,
    index,
    fields,
    removeError,
    name,
    errors,
    onErrors,
  } = props;
  const actualLanguage = get(fields, index);

  if (actualLanguage._destroy) {
    return (
      <>
      </>
    );
  }

  return (
    <>
      <Field component={RenderInput} type="hidden" name={`${keyName}.id`} />
      <Field
        component={RenderInput}
        error={onErrors(errors.url)}
        name={`${keyName}.url`}
        fluid
        required
        label="Url"
        placeholder="ejemplo: https://www.linkedin.com/in/jorge...."
      />
      <Field
        component={RenderTextArea}
        error={onErrors(errors.description)}
        name={`${keyName}.description`}
        fluid
        required
        label="Descripción"
        placeholder="Descripción"
      />
      <Button type="button" basic color="red" onClick={remove(fields, index, removeError, name)}>
        Eliminar url
      </Button>
      <Divider section />
    </>
  );
};

const RenderLaboralExperiences = (errors, onErrors) => ({
  fields,
  ...props
}) => (
  <Segment raised color="blue">
    <Grid>
      <Grid.Row>
        <Grid.Column width={11}>
          <Header as="h3" textAlign="left">
            <Icon name="suitcase" />
            Redes sociales
          </Header>
        </Grid.Column>
        <Grid.Column width={5}>
          <Button type="button" size="tiny" basic color="teal" fluid onClick={() => fields.push({})}>
            <Icon name="plus" />
            Agregar Link
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Divider />
    <>
      {fields.map((job, index) => (
        <SocialNetwork
          key={index.toString()}
          keyName={job}
          index={index}
          fields={fields}
          name="links"
          errors={errors[index] || {}}
          onErrors={onErrors}
          {...props}
        />
      ))}
    </>
  </Segment>
);

const SocialNetworks = ({ errors = [], onErrors = (e) => e, ...props }) => (
  <FieldArray
    name="links_attributes"
    rerenderOnEveryChange
    component={RenderLaboralExperiences(errors, onErrors)}
    {...props}
  />
);

export default SocialNetworks;
