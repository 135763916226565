import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import { Toast } from 'frontend/components/Form';
import * as referenceActions from 'frontend/actions/reference';
import * as applicantActions from 'frontend/actions/applicant';
import ProfileForm from 'frontend/components/ApplicantProfile/ProfileFormContainer';
import onErrors from 'helpers/errors';
import { Icon } from 'semantic-ui-react';

class CompleteProfile extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.showAchievedStudies = this.showAchievedStudies.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.state = { welcome: true };
  }

  componentDidMount() {
    const {
      actions: {
        getGenders,
        getDocumentTypes,
        getCountries,
        getMaritalStatuses,
        getAchievedStudies,
        getCareers,
        getIntegratorGenders,
        getProfessionalDevAreas,
        getPreloadApplicant,
      },
      MustCompleteProfile,
    } = this.props;
    if (!MustCompleteProfile) window.location = '/';

    getPreloadApplicant();
    getCountries();
    getDocumentTypes();
    getGenders();
    getIntegratorGenders();
    getMaritalStatuses();
    getAchievedStudies();
    getCareers();
    getProfessionalDevAreas();
  }

  handleDismiss(message) {
    return () => {
      const {
        actions: {
          dismissMessage,
        },
      } = this.props;
      dismissMessage(message);
    };
  }

  handleSubmit(data) {
    const url = '/api/v1/applicants/register_from_sso';
    const {
      actions: {
        registerApplicant,
      },
    } = this.props;
    registerApplicant(data, url).then(() => {
      const {
        applicant: {
          onSuccess,
        },
      } = this.props;
      if (onSuccess) {
        setTimeout(() => { window.location = '/'; }, 3500);
      }
    });
  }

  showAchievedStudies(id, arr) {
    if (id) {
      return arr.find((level) => level.value === id).degree === 'true';
    } return false;
  }

  render() {
    const {
      referenceDatum: {
        countries,
        genders,
        iGenders,
        documentTypes,
        maritalStatuses,
        achievedStudies,
        careers,
        professionalDevAreas,
      },
      applicant: {
        isFetching,
        errors,
        hasErrors,
        onSuccess,
        message,
      },
    } = this.props;
    const { welcome } = this.state;
    setTimeout(() => this.setState({ welcome: false }), 5000);
    return (
      <>
        {
          welcome
          && (
            <Toast
              info
              icon={<Icon name="address card" />}
              onDismiss={() => this.setState({ welcome: false })}
              header="Aún no completaste tu perfil, por favor completalo antes de continuar"
            />
          )
        }
        {
          onSuccess
          && (
            <Toast
              success
              icon={<Icon color="green" name="check" />}
              onDismiss={this.handleDismiss('onSuccess')}
              header="Tu perfil ha sido actualizado"
            />
          )
        }
        {
          hasErrors
          && (
            <Toast
              negative
              icon={<Icon color="red" name="x" />}
              onDismiss={this.handleDismiss('hasErrors')}
              content={message}
            />
          )
        }
        <ProfileForm
          fetching={isFetching}
          iGenders={iGenders}
          genders={genders}
          achievedStudies={achievedStudies}
          countries={countries}
          documentTypes={documentTypes}
          professionalDevAreas={professionalDevAreas}
          maritalStatuses={maritalStatuses}
          careers={careers}
          errors={errors}
          showAchievedStudies={this.showAchievedStudies}
          onSubmit={this.handleSubmit}
          onErrors={onErrors}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  applicant: state.applicant,
  referenceDatum: state.referenceDatum,
  MustCompleteProfile: state.loginApplicant.loggedUser.must_complete_profile,
  form: state.form,
});

function mapDispatchToProps(dispatch) {
  return ({
    actions: bindActionCreators({
      ...applicantActions,
      ...referenceActions,
    }, dispatch),
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(CompleteProfile);
