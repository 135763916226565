import {
  APPLICANT_GET_FAILURE,
  APPLICANT_GET_SUCCESS,
  APPLICANT_GET_REQUEST,

  REGISTER_APPLICANT_FAILURE,
  REGISTER_APPLICANT_SUCCESS,
  REGISTER_APPLICANT_REQUEST,
  ON_DISMISS_MESSAGE,

  APPLICANT_PROFILE_GET_REQUEST,
  APPLICANT_PROFILE_GET_SUCCESS,
  APPLICANT_PROFILE_GET_FAILURE,

  PROFILE_UPDATE_REQUEST,
  PROFILE_UPDATE_SUCCESS,
  PROFILE_UPDATE_FAILURE,
} from 'constants/applicant';

import axios from 'helpers/axios';

export const getPreloadApplicant = () => (dispatch) => {
  dispatch({ type: APPLICANT_GET_REQUEST });
  return axios({
    url: '/api/v1/applicants/sso_preload_data',
    method: 'get',
  }).then(
    (res) => {
      dispatch({ type: APPLICANT_GET_SUCCESS, payload: res.data });
    },
  ).catch((err) => dispatch({
    type: APPLICANT_GET_FAILURE,
    payload: {
      errors: err.response.data,
    },
  }));
};

export const registerApplicant = (form, url) => (dispatch) => {
  dispatch({ type: REGISTER_APPLICANT_REQUEST });
  return axios({
    url,
    data: {
      applicant: {
        email: form.email,
        password: form.password,
        password_confirmation: form.password_confirmation,
        username: form.username,
        profile_attributes: {
          ...form.profile,
          address_attributes: form.profile.address,
          phones_attributes: [
            {
              phone_type: 0,
              number: form.profile.profile_phone[1].number,
            },
            {
              phone_type: 1,
              number: form.profile.profile_phone[0].number,
            },
          ],
        },
      },
    },
    method: 'POST',
  }).then(
    (res) => {
      dispatch({ type: REGISTER_APPLICANT_SUCCESS, payload: res });
    },
  ).catch((err) => dispatch({
    type: REGISTER_APPLICANT_FAILURE,
    payload: {
      code: err.response.status,
      errors: err.response.data,
    },
  }));
};

export const dismissMessage = (message) => (dispatch) => (
  dispatch({
    type: ON_DISMISS_MESSAGE,
    payload: { message },
  })
);

export const getApplicantWithProfile = () => (dispatch) => {
  dispatch({ type: APPLICANT_PROFILE_GET_REQUEST });
  return axios({
    url: '/api/v1/applicants/current',
    method: 'get',
  }).then(
    (res) => {
      dispatch({ type: APPLICANT_PROFILE_GET_SUCCESS, payload: { data: res.data } });
    },
  ).catch((err) => {
    console.log(err);
    dispatch({
      type: APPLICANT_PROFILE_GET_FAILURE,
      payload: {
        errors: (err.response && err.response.data) ? err.response.data : err,
      },
    });
  });
};

export const updateProfileApplicant = (form) => (dispatch) => {
  dispatch({ type: PROFILE_UPDATE_REQUEST });
  return axios({
    url: '/api/v1/applicants/current',
    data: {
      applicant: {
        email: form.email,
        password: form.password,
        password_confirmation: form.password_confirmation,
        username: form.username,
        profile_attributes: {
          ...form.profile,
          address_attributes: form.profile.address,
          phones_attributes: [
            {
              phone_type: 0,
              number: form.profile.profile_phone[1].number,
            },
            {
              phone_type: 1,
              number: form.profile.profile_phone[0].number,
            },
          ],
        },
      },
    },
    method: 'put',
  }).then(
    (res) => {
      dispatch({ type: PROFILE_UPDATE_SUCCESS, payload: { data: res.data } });
    },
  ).catch((err) => dispatch({
    type: PROFILE_UPDATE_FAILURE,
    payload: {
      code: err.response.status,
      errors: err.response.data,
    },
  }));
};
