import { combineReducers } from 'redux';
import { reducer as formReducer } from 'redux-form';
import referenceDatum from './referenceDatum';
import loginApplicant from './loginApplicant';
import enums from './enums';
import applicant from './applicant';
import curriculum from './curriculum';
import publicJobOffers from './publicJobOffers';
import email from './email';

export const appReducer = combineReducers({
  referenceDatum,
  loginApplicant,
  applicant,
  curriculum,
  publicJobOffers,
  enums,
  email,
  form: formReducer,
});

export default appReducer;
