import React from 'react';
import {
  Form,
  Button,
  Dimmer,
  Container,
  Grid,
  Menu,
  Header,
  Icon,
  Loader,
} from 'semantic-ui-react';
import {
  WorkStudies,
  WorkExperiences,
  WorkObjectives,
  FileUpload,
} from 'frontend/containers/Curriculum/partials';
import { SocialNetworksForm } from './partials';

const CurriculumForm = ({
  handleSubmit,
  submitCallback,
  isFetching,
  errors,
  activeItem,
  handleItemClick,
  tabHasErrors,
  jobofferReturnId,
  ...props
}) => (
  <Container style={{ textAlign: 'center', marginTop: '1em', marginBottom: '1em' }}>
    <Dimmer active={isFetching}>
      <Loader> Cargando </Loader>
    </Dimmer>
    <Header as="h2">Curriculum Vitae</Header>
    <Grid>
      <Grid.Column width={3}>
        <Menu fluid vertical tabular>
          <Menu.Item
            name="Objetivos laborales"
            active={activeItem === 'Objetivos laborales'}
            onClick={handleItemClick}
          >
            Objetivos
            <br />
            laborales
            { Object.keys(errors.work_objectives).length > 0 && (
              <Icon color="red" name="times" />
            )}
          </Menu.Item>
          <Menu.Item
            name="Experiencias laborales"
            active={activeItem === 'Experiencias laborales'}
            onClick={handleItemClick}
          >
            Experiencias
            <br />
            laborales
            { tabHasErrors(errors.work_studies.job_experiences) && (
              <Icon color="red" name="times" />
            )}
          </Menu.Item>
          <Menu.Item
            name="Estudios y capacitaciones"
            active={activeItem === 'Estudios y capacitaciones'}
            onClick={handleItemClick}
          >
            Estudios y capacitaciones
            { (tabHasErrors(errors.work_studies.courses)
              || tabHasErrors(errors.work_studies.computing_skills)
              || tabHasErrors(errors.work_studies.formal_studies)
              || tabHasErrors(errors.work_studies.language_studies)) && (
              <Icon color="red" name="times" />
            )}
          </Menu.Item>
          <Menu.Item
            name="Redes sociales"
            active={activeItem === 'Redes sociales'}
            onClick={handleItemClick}
          >
            Redes
            <br />
            sociales
            { tabHasErrors(errors.work_studies.links) && (
              <Icon color="red" name="times" />
            )}
          </Menu.Item>
          <Menu.Item
            name="CV en archivo"
            active={activeItem === 'CV en archivo'}
            onClick={handleItemClick}
          >
            CV en
            <br />
            archivo
          </Menu.Item>
          {
            jobofferReturnId
            && (
              <Menu.Item
                name="Volver a la búsqueda laboral"
                onClick={() => { window.location = `/busquedas/${jobofferReturnId}`; }}
              />
            )

          }
        </Menu>
      </Grid.Column>
      <Grid.Column width={13}>
        <Form onSubmit={handleSubmit(submitCallback)}>
          { activeItem === 'Estudios y capacitaciones' && (
            <WorkStudies {...props} errors={errors.work_studies} />
          )}
          { activeItem === 'Experiencias laborales' && (
            <WorkExperiences {...props} errors={errors.work_studies.job_experiences} />
          )}

          { activeItem === 'Objetivos laborales' && (
            <WorkObjectives {...props} errors={errors.work_objectives} />
          )}
          { activeItem === 'Redes sociales' && (
            <SocialNetworksForm {...props} errors={errors.work_studies.links} />
          )}

          { activeItem === 'CV en archivo' && (
            <FileUpload {...props} errors={errors.work_studies.links} />
          )}
          { !(activeItem === 'CV en archivo') && (
            <Button primary fluid> Actualizar CV </Button>
          )}
        </Form>
      </Grid.Column>
    </Grid>
  </Container>
);

export default CurriculumForm;
