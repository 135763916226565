export const OFFER_TYPE_REQUEST = 'offer_type_request';
export const OFFER_TYPE_FAILURE = 'offer_type_failure';
export const OFFER_TYPE_SUCCESS = 'offer_type_success';

export const CONTRACT_TYPE_REQUEST = 'contract_type_request';
export const CONTRACT_TYPE_FAILURE = 'contract_type_failure';
export const CONTRACT_TYPE_SUCCESS = 'contract_type_success';

export const APPLICANTS_VISIBILITY_REQUEST = 'applicants_visibility_request';
export const APPLICANTS_VISIBILITY_FAILURE = 'applicants_visibility_failure';
export const APPLICANTS_VISIBILITY_SUCCESS = 'applicants_visibility_success';

export const CURRICULUMS_VISIBILITY_REQUEST = 'curriculums_visibility_request';
export const CURRICULUMS_VISIBILITY_FAILURE = 'curriculums_visibility_failure';
export const CURRICULUMS_VISIBILITY_SUCCESS = 'curriculums_visibility_success';

export const OWN_MOBILITY_GET_REQUEST = 'own-mobility-get-request';
export const OWN_MOBILITY_GET_SUCCESS = 'own-mobility-get-success';
export const OWN_MOBILITY_GET_FAILURE = 'own-mobility-get-failure';

export const DRIVER_LICENSE_GET_REQUEST = 'driver-license-get-request';
export const DRIVER_LICENSE_GET_SUCCESS = 'driver-license-get-success';
export const DRIVER_LICENSE_GET_FAILURE = 'driver-license-get-failure';

export const FORMAL_STUDY_STATUSES_GET_REQUEST = 'formal-study-statuses-get-request';
export const FORMAL_STUDY_STATUSES_GET_SUCCESS = 'formal-study-statuses-get-success';
export const FORMAL_STUDY_STATUSES_GET_FAILURE = 'formal-study-statuses-get-failure';

export const LANGUAGE_STUDY_LEVEL_GET_REQUEST = 'language-study-levels-get-request';
export const LANGUAGE_STUDY_LEVEL_GET_SUCCESS = 'language-study-levels-get-success';
export const LANGUAGE_STUDY_LEVEL_GET_FAILURE = 'language-study-levels-get-failure';

export const CURRICULUM_SKILLS_LEVEL_GET_REQUEST = 'curriculum-skills-levels-get-request';
export const CURRICULUM_SKILLS_LEVEL_GET_SUCCESS = 'curriculum-skills-levels-get-success';
export const CURRICULUM_SKILLS_LEVEL_GET_FAILURE = 'curriculum-skills-levels-get-failure';

export const COURSE_DURATION_UNITS_GET_REQUEST = 'course-duration-units-get-request';
export const COURSE_DURATION_UNITS_GET_SUCCESS = 'course-duration-units-get-success';
export const COURSE_DURATION_UNITS_GET_FAILURE = 'course-duration-units-get-failure';
