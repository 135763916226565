import React from 'react';
import {
  Container,
  Header,
  Segment,
  Divider,
  Grid,
  Button,
  Icon,
} from 'semantic-ui-react';
import { Link } from 'react-router-dom';
import moment from 'moment';

const format = (arr, id) => {
  const element = arr.find((el) => el.value === id);
  return element ? element.text : '-';
};

export default ({
  jobOffer,
  contractTypes,
  id,
  user,
  apply,
  disband,
}) => {
  moment.locale('es');
  return (
    <Container className="job-offer-container">
      <Header as="h2" textAlign="center">
        {jobOffer.ad_text}
      </Header>
      <Segment raised color="blue">
        <Divider horizontal> Datos de la empresa </Divider>
        <Grid celled stackable>
          <Grid.Row>
            <Grid.Column width={2}>
              <label>Fecha de pedido:</label>
              <br />
              <p className="data">{moment(jobOffer.start_date).format('L') || '-'}</p>
            </Grid.Column>
            <Grid.Column width={2}>
              <label>Fecha de incorporacion aproximada:</label>
              <br />
              <p className="important-data">{moment(jobOffer.incorporation_date).format('L') || '-'}</p>
            </Grid.Column>
            <Grid.Column width={6}>
              <label>Puesto:</label>
              <br />
              <p className="important-data">{jobOffer.position_title || '-'}</p>
            </Grid.Column>
            <Grid.Column width={6}>
              <label>Cargo:</label>
              <br />
              <p className="important-data">{jobOffer.position_description || '-'}</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment raised color="blue">
        <Divider horizontal> Sobre el puesto </Divider>
        <Grid celled stackable>
          <Grid.Row>
            <Grid.Column width={8}>
              <label>Perfil requerido:</label>
              <br />
              <p className="important-data">{jobOffer.expected_profile || '-'}</p>
            </Grid.Column>
            <Grid.Column width={8}>
              <label>Principales tareas a realizar:</label>
              <br />
              <p className="important-data">{jobOffer.responsibilities || '-'}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={6}>
              <label>Conocimientos:</label>
              <br />
              <p className="important-data">{jobOffer.expected_abilities || '-'}</p>
            </Grid.Column>
            <Grid.Column width={6}>
              <label>Experiencia laboral:</label>
              <br />
              <p className="important-data">{jobOffer.expected_job_experience || '-'}</p>
            </Grid.Column>
            <Grid.Column width={4}>
              <label>Estudio requerido:</label>
              <br />
              <p className="important-data">{jobOffer.expected_studies || '-'}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={4}>
              <label>Sexo:</label>
              <br />
              <p className="data">{jobOffer.expected_gender_id === 2 ? 'Femenino' : 'Masculino'}</p>
            </Grid.Column>
            <Grid.Column width={4}>
              <label>¿Se requiere disponibilidad para viajar?</label>
              <br />
              <p className="important-data">{jobOffer.travelling_availability ? 'Si' : 'No'}</p>
            </Grid.Column>
            <Grid.Column width={4}>
              <label>¿Se requiere que tenga gente a cargo?</label>
              <br />
              <p className="data">{jobOffer.people_in_charge ? 'Si' : 'No'}</p>
            </Grid.Column>
            <Grid.Column width={4}>
              <label>Edad esperada:</label>
              <br />
              <p className="data">{jobOffer.expected_age || '-'}</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
      </Segment>
      <Segment raised color="blue">
        <Divider horizontal> Condiciones de contratación </Divider>
        <Grid celled stackable>
          <Grid.Row>
            <Grid.Column width={6}>
              <label>Remuneración pública:</label>
              <br />
              <p className="important-data">{jobOffer.public_salary_description || '-'}</p>
            </Grid.Column>
            <Grid.Column width={4}>
              <label>Modalidad de contratación:</label>
              <br />
              <p className="data">{format(contractTypes, jobOffer.contract_type)}</p>
            </Grid.Column>
            <Grid.Column width={6}>
              <label>Lugar de trabajo:</label>
              <br />
              <p className="important-data">{jobOffer.location || '-'}</p>
            </Grid.Column>
          </Grid.Row>
          <Grid.Row>
            <Grid.Column width={8}>
              <label>Horario:</label>
              <br />
              <p className="important-data">{jobOffer.work_schedule || '-'}</p>
            </Grid.Column>
            <Grid.Column width={8}>
              <label>Observaciones:</label>
              <br />
              <p className="data">{jobOffer.comments || '-'}</p>
            </Grid.Column>
          </Grid.Row>
        </Grid>
        <div style={{ textAlign: 'right' }}>
          <Button as={Link} to="/busquedas" size="large">
            <Icon name="arrow left" />
            Volver al listado de búsquedas
          </Button>
          { !user && (
            <Button
              primary
              size="large"
              as={Link}
              to={`/iniciar-sesion-postulante?joboffer=${id}`}
            >
              <Icon name="user" />
              Iniciá sesión para postularte
            </Button>
          )}
          {user && user.role === 'applicant' && !jobOffer.active_user_has_applied && (
            <Button color="green" size="large" onClick={apply}>
              <Icon name="id card" />
              Postulate
            </Button>
          )}
          {user && user.role === 'applicant' && jobOffer.active_user_has_applied && (
            <Button color="red" size="large" onClick={disband}>
              <Icon name="user times" />
              Dar de baja postulación
            </Button>
          )}
        </div>
      </Segment>
    </Container>
  );
};
