import React from 'react';
import { Divider, Form, Segment } from 'semantic-ui-react';
import { Field } from 'redux-form';
import { RenderSelect } from 'frontend/components/Form';

const AchievedStudies = (props) => {
  const {
    achievedStudies,
    selectedAchievedStudy,
    careers,
    errors,
    showAchievedStudies,
    onErrors,
  } = props;
  return (
    <Segment raised color="blue">
      <Divider horizontal>Estudios realizados</Divider>
      <Form.Group widths="equal">
        <Field
          component={RenderSelect}
          error={onErrors(errors['profile.achieved_study'])}
          name="profile.achieved_study_id"
          options={achievedStudies}
          fluid
          label="Nivel de estudios alcanzados"
          placeholder="Nivel de estudios alcanzados"
          required
        />
        {
          showAchievedStudies(selectedAchievedStudy, achievedStudies)
          && (
          <Field
            component={RenderSelect}
            error={onErrors(errors['profile.career'])}
            name="profile.career_id"
            options={careers}
            fluid
            label="Carrera de grado"
            placeholder="Carrera de grado"
            required
          />
          )
        }
      </Form.Group>
    </Segment>
  );
};

export default AchievedStudies;
