import {
  OFFER_TYPE_REQUEST,
  OFFER_TYPE_FAILURE,
  OFFER_TYPE_SUCCESS,

  CONTRACT_TYPE_REQUEST,
  CONTRACT_TYPE_FAILURE,
  CONTRACT_TYPE_SUCCESS,

  APPLICANTS_VISIBILITY_REQUEST,
  APPLICANTS_VISIBILITY_FAILURE,
  APPLICANTS_VISIBILITY_SUCCESS,

  CURRICULUMS_VISIBILITY_REQUEST,
  CURRICULUMS_VISIBILITY_FAILURE,
  CURRICULUMS_VISIBILITY_SUCCESS,

  OWN_MOBILITY_GET_REQUEST,
  OWN_MOBILITY_GET_SUCCESS,
  OWN_MOBILITY_GET_FAILURE,

  DRIVER_LICENSE_GET_REQUEST,
  DRIVER_LICENSE_GET_SUCCESS,
  DRIVER_LICENSE_GET_FAILURE,

  FORMAL_STUDY_STATUSES_GET_REQUEST,
  FORMAL_STUDY_STATUSES_GET_SUCCESS,
  FORMAL_STUDY_STATUSES_GET_FAILURE,

  LANGUAGE_STUDY_LEVEL_GET_REQUEST,
  LANGUAGE_STUDY_LEVEL_GET_SUCCESS,
  LANGUAGE_STUDY_LEVEL_GET_FAILURE,

  CURRICULUM_SKILLS_LEVEL_GET_REQUEST,
  CURRICULUM_SKILLS_LEVEL_GET_SUCCESS,
  CURRICULUM_SKILLS_LEVEL_GET_FAILURE,

  COURSE_DURATION_UNITS_GET_REQUEST,
  COURSE_DURATION_UNITS_GET_SUCCESS,
  COURSE_DURATION_UNITS_GET_FAILURE,
} from 'constants/enums';

import initialState from 'frontend/state/enums';

export default (state = initialState, action) => {
  switch (action.type) {
    case OFFER_TYPE_REQUEST:
    case CONTRACT_TYPE_REQUEST:
    case APPLICANTS_VISIBILITY_REQUEST:
    case CURRICULUMS_VISIBILITY_REQUEST:
    case OWN_MOBILITY_GET_REQUEST:
    case DRIVER_LICENSE_GET_REQUEST:
    case FORMAL_STUDY_STATUSES_GET_REQUEST:
    case LANGUAGE_STUDY_LEVEL_GET_REQUEST:
    case CURRICULUM_SKILLS_LEVEL_GET_REQUEST:
    case COURSE_DURATION_UNITS_GET_REQUEST:
    {
      return {
        ...state,
        isFetching: true,
        errors: '',
      };
    }
    case OFFER_TYPE_SUCCESS:
    case CONTRACT_TYPE_SUCCESS:
    case APPLICANTS_VISIBILITY_SUCCESS:
    case CURRICULUMS_VISIBILITY_SUCCESS:
    case OWN_MOBILITY_GET_SUCCESS:
    case DRIVER_LICENSE_GET_SUCCESS:
    case FORMAL_STUDY_STATUSES_GET_SUCCESS:
    case LANGUAGE_STUDY_LEVEL_GET_SUCCESS:
    case CURRICULUM_SKILLS_LEVEL_GET_SUCCESS:
    case COURSE_DURATION_UNITS_GET_SUCCESS:
    {
      const {
        data,
        name,
      } = action.payload;
      return {
        ...state,
        [name]: data,
        isFetching: false,
        errors: '',
      };
    }
    case OFFER_TYPE_FAILURE:
    case CONTRACT_TYPE_FAILURE:
    case APPLICANTS_VISIBILITY_FAILURE:
    case CURRICULUMS_VISIBILITY_FAILURE:
    case OWN_MOBILITY_GET_FAILURE:
    case DRIVER_LICENSE_GET_FAILURE:
    case FORMAL_STUDY_STATUSES_GET_FAILURE:
    case LANGUAGE_STUDY_LEVEL_GET_FAILURE:
    case CURRICULUM_SKILLS_LEVEL_GET_FAILURE:
    case COURSE_DURATION_UNITS_GET_FAILURE:
    {
      const {
        errors,
      } = action.payload;
      return {
        ...state,
        isFetching: false,
        errors,
      };
    }
    default:
      return state;
  }
};
