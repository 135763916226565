export default {
  jobOfferOfferTypes: [],
  jobOfferContractTypes: [],
  jobOfferApplicantsVisibility: [],
  jobOfferCurriculumsVisibility: [],
  curriculumOwnMobility: [],
  curriculumDriverLicense: [],
  formalStudyStatuses: [],
  languageLevels: [],
  courseDurationUnits: [],
  curriculumSkillLevels: [],
  errors: '',
  isFetching: false,
};
