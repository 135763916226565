import React from 'react';
import {
  Segment,
  Divider,
  Form,
} from 'semantic-ui-react';
import { Field } from 'redux-form';

import { RenderTextArea, RenderCheckbox, RenderSelect } from 'frontend/components/Form';

const LaboralObjectives = (
  {
    errors = {},
    onErrors = (e) => e,
    ownMobility,
    driverLicense,
  },
) => (
  <Segment raised color="blue">
    <Form.Group widths="equal">
      <Field
        component={RenderTextArea}
        error={onErrors(errors.personal_goals)}
        name="personal_goals"
        fluid
        label="Objetivos personales"
        placeholder="Objetivos personales"
      />
    </Form.Group>
    <Form.Group widths="equal">
      <Field
        component={RenderTextArea}
        error={onErrors(errors.work_goals)}
        name="work_goals"
        fluid
        label="Objetivos laborales"
        placeholder="Objetivos laborales"
      />
    </Form.Group>
    <Form.Group widths="equal">
      <Field
        component={RenderTextArea}
        error={onErrors(errors.professional_goals)}
        name="professional_goals"
        fluid
        label="Objetivos profesionales"
        placeholder="Objetivos profesionales"
      />
    </Form.Group>

    <Divider section />

    {/* Campos de disponibiidad */}
    <Form.Group widths="equal">
      <Field
        component={RenderCheckbox}
        error={onErrors(errors.country_relocation_availability)}
        name="country_relocation_availability"
        label="Aceptaría reubicarme en otro país"
        placeholder="Aceptaría reubicarme en otro país"
      />
      <Field
        component={RenderCheckbox}
        error={onErrors(errors.province_relocation_availability)}
        name="province_relocation_availability"
        label="Aceptaría reubicarme en otra provincia"
        placeholder="Aceptaría reubicarme en otra provincia"
      />
    </Form.Group>
    <Form.Group widths="equal">
      <Field
        component={RenderSelect}
        error={onErrors(errors.own_mobility)}
        name="own_mobility"
        label="Movilidad propia"
        placeholder="Movilidad propia"
        options={ownMobility}
      />
      <Field
        component={RenderSelect}
        error={onErrors(errors.drivers_license)}
        name="drivers_license"
        label="Registro de conductor"
        placeholder="Registro de conductor"
        options={driverLicense}
      />
    </Form.Group>
  </Segment>
);

export default LaboralObjectives;
