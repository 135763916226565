import {
  RESEND_EMAIL_REQUEST,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_FAILURE,

  REQUEST_PASSWORD_CHANGE_EMAIL_REQUEST,
  REQUEST_PASSWORD_CHANGE_EMAIL_SUCCESS,
  REQUEST_PASSWORD_CHANGE_EMAIL_FAILURE,

  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,

  ON_DISMISS_MESSAGE,
} from 'frontend/constants/email';

export default (state = {}, action) => {
  switch (action.type) {
    case ON_DISMISS_MESSAGE: {
      return {
        ...state,
        hasError: false,
      };
    }
    case REQUEST_PASSWORD_CHANGE_EMAIL_REQUEST:
    case UPDATE_PASSWORD_REQUEST:
    case RESEND_EMAIL_REQUEST: {
      return {
        ...state,
        isFetching: true,
        hasError: false,
        success: false,
      };
    }
    case REQUEST_PASSWORD_CHANGE_EMAIL_SUCCESS:
    case UPDATE_PASSWORD_SUCCESS:
    case RESEND_EMAIL_SUCCESS: {
      return {
        ...state,
        isFetching: false,
        success: true,
        error: '',
      };
    }
    case REQUEST_PASSWORD_CHANGE_EMAIL_FAILURE:
    case RESEND_EMAIL_FAILURE: {
      const {
        code,
        errors,
      } = action.payload;
      let message;
      if (code === 500) {
        message = 'Ocurrió un error en el servidor, intente más tarde';
      } else {
        message = errors;
      }
      return {
        ...state,
        error: message,
        hasError: true,
        isFetching: false,
      };
    }
    case UPDATE_PASSWORD_FAILURE:
    {
      const {
        code,
        error,
        errors,
      } = action.payload;
      let message;
      if (code === 500) {
        message = 'Ocurrió un error en el servidor, intente más tarde';
      } else {
        message = error;
      }
      return {
        ...state,
        errors,
        error: message,
        hasError: true,
        isFetching: false,
      };
    }


    default:
      return state;
  }
};
