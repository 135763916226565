import {
  OFFER_TYPE_REQUEST,
  OFFER_TYPE_FAILURE,
  OFFER_TYPE_SUCCESS,

  CONTRACT_TYPE_REQUEST,
  CONTRACT_TYPE_FAILURE,
  CONTRACT_TYPE_SUCCESS,

  APPLICANTS_VISIBILITY_REQUEST,
  APPLICANTS_VISIBILITY_FAILURE,
  APPLICANTS_VISIBILITY_SUCCESS,

  CURRICULUMS_VISIBILITY_REQUEST,
  CURRICULUMS_VISIBILITY_FAILURE,
  CURRICULUMS_VISIBILITY_SUCCESS,

  OWN_MOBILITY_GET_REQUEST,
  OWN_MOBILITY_GET_SUCCESS,
  OWN_MOBILITY_GET_FAILURE,

  DRIVER_LICENSE_GET_REQUEST,
  DRIVER_LICENSE_GET_SUCCESS,
  DRIVER_LICENSE_GET_FAILURE,

  FORMAL_STUDY_STATUSES_GET_REQUEST,
  FORMAL_STUDY_STATUSES_GET_SUCCESS,
  FORMAL_STUDY_STATUSES_GET_FAILURE,

  LANGUAGE_STUDY_LEVEL_GET_REQUEST,
  LANGUAGE_STUDY_LEVEL_GET_SUCCESS,
  LANGUAGE_STUDY_LEVEL_GET_FAILURE,

  CURRICULUM_SKILLS_LEVEL_GET_REQUEST,
  CURRICULUM_SKILLS_LEVEL_GET_SUCCESS,
  CURRICULUM_SKILLS_LEVEL_GET_FAILURE,

  COURSE_DURATION_UNITS_GET_REQUEST,
  COURSE_DURATION_UNITS_GET_SUCCESS,
  COURSE_DURATION_UNITS_GET_FAILURE,
} from 'constants/enums';

import { fetchFromAPI, asOptionsForSelect } from 'helpers/reference';

const ArrayToSelectMapping = (arr) => (
  arr.map((each, index) => ({
    key: index,
    text: each[1],
    value: each[0],
  }))
);

export const getOfferTypes = () => (
  (dispatch) => {
    dispatch({ type: OFFER_TYPE_REQUEST });
    return fetchFromAPI('job_offer_offer_type', {}, 'enums').then((resp) => (
      dispatch({
        type: OFFER_TYPE_SUCCESS,
        payload: {
          data: ArrayToSelectMapping(resp.data),
          name: 'jobOfferOfferTypes',
        },
      })))
      .catch((error) => dispatch({
        type: OFFER_TYPE_FAILURE,
        payload: {
          errors: error.response,
        },
      }));
  }
);

export const getContractTypes = () => (
  (dispatch) => {
    dispatch({ type: CONTRACT_TYPE_REQUEST });
    return fetchFromAPI('job_offer_contract_type', {}, 'enums').then((resp) => (
      dispatch({
        type: CONTRACT_TYPE_SUCCESS,
        payload: {
          data: ArrayToSelectMapping(resp.data),
          name: 'jobOfferContractTypes',
        },
      })))
      .catch((error) => dispatch({
        type: CONTRACT_TYPE_FAILURE,
        payload: {
          errors: error.response,
        },
      }));
  }
);

export const getApplicantsVisibilities = () => (
  (dispatch) => {
    dispatch({ type: APPLICANTS_VISIBILITY_REQUEST });
    return fetchFromAPI('job_offer_applicants_visibility', {}, 'enums').then((resp) => (
      dispatch({
        type: APPLICANTS_VISIBILITY_SUCCESS,
        payload: {
          data: ArrayToSelectMapping(resp.data),
          name: 'jobOfferApplicantsVisibility',
        },
      })))
      .catch((error) => dispatch({
        type: APPLICANTS_VISIBILITY_FAILURE,
        payload: {
          errors: error.response,
        },
      }));
  }
);

export const getCurriculumsVisibilities = () => (
  (dispatch) => {
    dispatch({ type: CURRICULUMS_VISIBILITY_REQUEST });
    return fetchFromAPI('job_offer_curriculums_visibility', {}, 'enums').then((resp) => (
      dispatch({
        type: CURRICULUMS_VISIBILITY_SUCCESS,
        payload: {
          data: ArrayToSelectMapping(resp.data),
          name: 'jobOfferCurriculumsVisibility',
        },
      })))
      .catch((error) => dispatch({
        type: CURRICULUMS_VISIBILITY_FAILURE,
        payload: {
          errors: error.response,
        },
      }));
  }
);

export const getDriverLicenses = () => (dispatch) => {
  dispatch({ type: DRIVER_LICENSE_GET_REQUEST });
  return fetchFromAPI('curriculum_drivers_license', {}, 'enums').then((res) => {
    dispatch({
      type: DRIVER_LICENSE_GET_SUCCESS,
      payload: {
        name: 'curriculumDriverLicense',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => dispatch({
    type: DRIVER_LICENSE_GET_FAILURE,
    payload: {
      errors: err.response,
    },
  }));
};

export const getOwnMobilities = () => (dispatch) => {
  dispatch({ type: OWN_MOBILITY_GET_REQUEST });
  return fetchFromAPI('curriculum_own_mobility', {}, 'enums').then((res) => {
    dispatch({
      type: OWN_MOBILITY_GET_SUCCESS,
      payload: {
        name: 'curriculumOwnMobility',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => dispatch({
    type: OWN_MOBILITY_GET_FAILURE,
    payload: {
      errors: err.response,
    },
  }));
};

export const getFormalStudyStatuses = () => (dispatch) => {
  dispatch({ type: FORMAL_STUDY_STATUSES_GET_REQUEST });
  return fetchFromAPI('formal_study_statuses', {}, 'enums').then((res) => {
    dispatch({
      type: FORMAL_STUDY_STATUSES_GET_SUCCESS,
      payload: {
        name: 'formalStudyStatuses',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => dispatch({
    type: FORMAL_STUDY_STATUSES_GET_FAILURE,
    payload: {
      errors: err.response,
    },
  }));
};

export const getLanguageLevels = () => (dispatch) => {
  dispatch({ type: LANGUAGE_STUDY_LEVEL_GET_REQUEST });
  return fetchFromAPI('language_study_levels', {}, 'enums').then((res) => {
    dispatch({
      type: LANGUAGE_STUDY_LEVEL_GET_SUCCESS,
      payload: {
        name: 'languageLevels',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => dispatch({
    type: LANGUAGE_STUDY_LEVEL_GET_FAILURE,
    payload: {
      errors: err.response,
    },
  }));
};

export const getCourseDurationUnits = () => (dispatch) => {
  dispatch({ type: COURSE_DURATION_UNITS_GET_REQUEST });
  return fetchFromAPI('courses_duration_units', {}, 'enums').then((res) => {
    dispatch({
      type: COURSE_DURATION_UNITS_GET_SUCCESS,
      payload: {
        name: 'courseDurationUnits',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => dispatch({
    type: COURSE_DURATION_UNITS_GET_FAILURE,
    payload: {
      errors: err.response,
    },
  }));
};
export const getCurriculumSkillLevels = () => (dispatch) => {
  dispatch({ type: CURRICULUM_SKILLS_LEVEL_GET_REQUEST });
  return fetchFromAPI('curriculum_skills_levels', {}, 'enums').then((res) => {
    dispatch({
      type: CURRICULUM_SKILLS_LEVEL_GET_SUCCESS,
      payload: {
        name: 'curriculumSkillLevels',
        data: asOptionsForSelect(res.data),
      },
    });
  }).catch((err) => dispatch({
    type: CURRICULUM_SKILLS_LEVEL_GET_FAILURE,
    payload: {
      errors: err.response,
    },
  }));
};
