import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as referenceActions from 'frontend/actions/reference';
import { WorkExperienceForm } from 'frontend/components/CurriculumForm/partials';

const usePreinitializelWorkExperience = (actions) => {
  const {
    getJobCategories,
  } = actions;

  useEffect(() => {
    getJobCategories();
  }, [
    getJobCategories,
  ]);
};
const WorkExperience = ({
  actions,
  ...props
}) => {
  usePreinitializelWorkExperience(actions);

  return (
    <>
      <WorkExperienceForm
        {...props}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  jobCategories: state.referenceDatum.jobCategories,
});

function mapDispatchToProps(dispatch) {
  return ({
    actions: bindActionCreators({
      ...referenceActions,
    }, dispatch),
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkExperience);
