import React, { useState } from 'react';
import {
  Button,
  Grid,
  Image,
  Segment,
  Form,
  Header,
  Input,
  Icon,
} from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import { Toast } from 'frontend/components/Form';
import prolabLogo from 'images/prolab-logo';
import * as emailActions from 'frontend/actions/email';
import { connect } from 'react-redux';

const handleSubmit = (resendEmail, email) => () => (resendEmail(email));

const handleDismiss = (dismissMessage) => () => (dismissMessage());

const ResendEmail = (props) => {
  const {
    actions: {
      resendEmail,
      dismissMessage,
    },
    hasError,
    error,
    success,
  } = props;
  const [email, setEmail] = useState('');
  return (
    <Grid textAlign="center" style={{ height: '70vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        {
          !success
            ? (
              <>
                <Header as="h2" color="teal">
                  <Image src={prolabLogo} />
                  <br />
                  Ingresa un correo electrónico para reenviar el correo de confirmacion
                </Header>
                <Form size="large" onSubmit={handleSubmit(resendEmail, email)}>
                  <Segment stacked>
                    <Form.Field required style={{ textAlign: 'left' }}>
                      <label>Correo electrónico</label>
                      <Input
                        type="email"
                        fluid
                        onChange={(e) => setEmail(e.target.value)}
                        icon="envelope"
                        iconPosition="left"
                        placeholder="Correo electrónico"
                        name="email"
                      />
                    </Form.Field>
                    <Button color="teal" fluid size="large">
                      Enviar correo
                    </Button>
                  </Segment>
                </Form>
                {
                  hasError
                  && (
                    <Toast
                      negative
                      icon={<Icon color="red" name="x" />}
                      onDismiss={handleDismiss(dismissMessage)}
                      content={error}
                    />
                  )
                }
              </>
            )
            : (
              <Header as="h2" color="teal">
                <Image src={prolabLogo} />
                <br />
                El Correo fue enviado a
                {' '}
                {email}
              </Header>
            )
        }
      </Grid.Column>
    </Grid>
  );
};

const mapStateToProps = (state) => ({
  error: state.email.error,
  hasError: state.email.hasError,
  success: state.email.success,
});

function mapDispatchToProps(dispatch) {
  return ({
    actions: bindActionCreators({
      ...emailActions,
    }, dispatch),
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(ResendEmail);
