/* helper para manejar arrayField de react-redux */

export const remove = (fields, index, removeErrors, name) => (
  () => {
    const deletedField = fields.get(index);
    if (deletedField.id) {
      fields.push({ id: deletedField.id, _destroy: true });
    }
    fields.remove(index);
    removeErrors(name, index);
  }
);

export const get = (fields, index) => (fields.get(index));
