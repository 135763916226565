import React from 'react';
import { Form, Input, Popup } from 'semantic-ui-react';

const renderInput = ({
  error,
  input,
  label,
  inline,
  width,
  fluid,
  placeholder,
  type,
  disabled,
  required,
  inputProps,
  min,
  max,
  popupText,
}) => (
  <Form.Field
    width={width}
    inline={inline}
    required={required}
    error={Boolean(error)}
  >
    <label>{label}</label>
    {typeof popupText === 'string' && popupText !== ''
      ? (
        <Popup
          content={popupText}
          on="focus"
          trigger={(
            <Input
              {...inputProps}
              fluid={fluid}
              name={input.name}
              value={input.value}
              required={required}
              placeholder={placeholder}
              type={type}
              min={min}
              max={max}
              disabled={disabled}
              onChange={(e, { value }) => input.onChange(value)}
            />
          )}
        />
      ) : (
        <Input
          {...inputProps}
          fluid={fluid}
          name={input.name}
          value={input.value}
          required={required}
          placeholder={placeholder}
          type={type}
          min={min}
          disabled={disabled}
          max={max}
          onChange={(e, { value }) => input.onChange(value)}
        />
      )}
    <div>{error && <span style={{ color: 'red' }}>{error}</span>}</div>
  </Form.Field>
);

export default renderInput;
