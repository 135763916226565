import moment from 'moment';

moment.locale('es');

export default {
  email: '',
  password: '',
  password_confirmation: '',
  username: '',
  profile: {
    job_expectation_ids: [],
    job_experience_ids: [],
    surname: '',
    name: '',
    self_perceived_gender_id: '',
    i_document_type_id: '',
    document_number: '',
    children: '',
    birth_date: moment().subtract(18, 'years').format('L'),
    birth_city_name: '',
    birth_city_id: '',
    address: {
      street: '',
      number: '',
      adjacent_streets: '',
      story: '',
      room_number: '',
      city_name: '',
      city_id: '',
    },
    i_gender_id: '',
    birth_country_code: '',
    marital_status_id: '',
    achieved_study_id: '',
    profile_phone: [
      {
        number: '',
      },
      {
        number: '',
      },
    ],
  },
  isFetching: false,
  errors: '',
  hasErrors: false,
  onSuccess: false,
  isCompleted: false,
  enableReinitialize: true,
};
