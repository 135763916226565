import React from 'react';
import { Form, Dropdown, Popup } from 'semantic-ui-react';

const RenderSelect = (field) => (
  <Form.Field
    width={field.width}
    inline={field.inline}
    required={field.required}
    disabled={field.disabled}
    error={Boolean(field.error)}
  >
    { field.content
      ? (<Popup content={field.content} trigger={<label>{field.label}</label>} />)
      : (<label>{field.label}</label>)}
    <Dropdown
      name={field.input.name}
      onChange={(e, { value }) => field.input.onChange(value)}
      options={field.options}
      required={field.required}
      noResultsMessage="No se encontraron resultados."
      placeholder={field.placeholder}
      value={field.input.value}
      multiple={field.multiple}
      selection
      clearable
      search
      fluid={field.fluid}
    />
    <div>{field.error && <span style={{ color: 'red' }}>{field.error}</span>}</div>
  </Form.Field>
);

export default RenderSelect;
