import {
  RESEND_EMAIL_REQUEST,
  RESEND_EMAIL_SUCCESS,
  RESEND_EMAIL_FAILURE,

  REQUEST_PASSWORD_CHANGE_EMAIL_REQUEST,
  REQUEST_PASSWORD_CHANGE_EMAIL_SUCCESS,
  REQUEST_PASSWORD_CHANGE_EMAIL_FAILURE,

  UPDATE_PASSWORD_REQUEST,
  UPDATE_PASSWORD_SUCCESS,
  UPDATE_PASSWORD_FAILURE,

  ON_DISMISS_MESSAGE,
} from 'frontend/constants/email';

import axios from 'helpers/axios';

export const dismissMessage = () => (dispatch) => (
  dispatch({
    type: ON_DISMISS_MESSAGE,
  })
);

export const resendEmail = (email) => (dispatch) => {
  dispatch({ type: RESEND_EMAIL_REQUEST });
  return axios({
    url: 'confirmaciones/resend',
    method: 'POST',
    data: {
      email,
    },
  }).then(() => dispatch({ type: RESEND_EMAIL_SUCCESS }))
    .catch((err) => dispatch({
      type: RESEND_EMAIL_FAILURE,
      payload: {
        code: err.response.code,
        errors: err.response.data.error,
      },
    }));
};

export const requestPasswordChange = (email) => (dispatch) => {
  dispatch({ type: REQUEST_PASSWORD_CHANGE_EMAIL_REQUEST });
  return axios({
    url: '/passwords/change_password_request',
    method: 'POST',
    data: {
      email,
    },
  }).then(() => dispatch({ type: REQUEST_PASSWORD_CHANGE_EMAIL_SUCCESS }))
    .catch((err) => dispatch({
      type: REQUEST_PASSWORD_CHANGE_EMAIL_FAILURE,
      payload: {
        code: err.response.status,
        errors: err.response.data.error,
      },
    }));
};

export const updatePassword = (password, confirmPassword, code) => (dispatch) => {
  dispatch({ type: UPDATE_PASSWORD_REQUEST });
  return axios({
    url: '/passwords/update_password',
    method: 'POST',
    data: {
      code,
      applicant: {
        password_confirmation: confirmPassword,
        password,
      },
    },
  }).then(() => dispatch({ type: UPDATE_PASSWORD_SUCCESS }))
    .catch((err) => dispatch({
      type: UPDATE_PASSWORD_FAILURE,
      payload: {
        code: err.response.status,
        error: err.response.data.error,
        errors: err.response.data.errors,
      },
    }));
};
