import React from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as Actions from 'frontend/actions/public-job-offers';
import PublicJobOffersList from 'frontend/components/PublicJobOffers/PublicJobOffersList';
import Filter from 'frontend/components/PublicJobOffers/Filter';
import {
  Segment,
  Pagination,
  Container,
} from 'semantic-ui-react';

class PublicJobOffers extends React.Component {
  constructor(props) {
    super(props);
    this.state = { filter: '' };
    this.handleChange = this.handleChange.bind(this);
    this.handleFilter = this.handleFilter.bind(this);
    this.handlePageChange = this.handlePageChange.bind(this);
  }

  componentDidMount() {
    const { actions } = this.props;
    actions.getPublicJobOffers();
  }

  handlePageChange(_, { activePage }) {
    const { actions } = this.props;
    const { filter } = this.state;
    actions.getPublicJobOffers(activePage, filter);
  }

  handleFilter() {
    const { actions } = this.props;
    const { filter } = this.state;
    actions.getPublicJobOffers(1, filter);
  }

  handleChange(_, { value }) {
    this.setState({ filter: value });
  }

  render() {
    const {
      publicJobOffers: {
        public_job_offers,
        isFetching,
        total_pages,
        total_elements,
      },
    } = this.props;
    return (
      <>
        <h1>Búsquedas abiertas</h1>
        <Segment stacked>
          <Filter onSubmit={this.handleFilter} onChange={this.handleChange} />
          { total_elements > 0 ? (
            <>
              <PublicJobOffersList publicJobOffers={public_job_offers} isFetching={isFetching} />
              {total_pages > 1
                && (
                <Container textAlign="center">
                  <Pagination
                    boundaryRange={0}
                    defaultActivePage={1}
                    ellipsisItem={null}
                    firstItem={null}
                    lastItem={null}
                    siblingRange={1}
                    totalPages={total_pages}
                    onPageChange={this.handlePageChange}
                  />
                </Container>
                )}
            </>
          ) : (
            'no se encontraron elementos para mostrar'
          )}
        </Segment>
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  publicJobOffers: state.publicJobOffers,
});

function mapDispatchToProps(dispatch) {
  return ({
    actions: bindActionCreators({
      ...Actions,
    }, dispatch),
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(PublicJobOffers);
