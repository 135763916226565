export default {
  success: false,
  hasErrors: false,
  isFetching: false,
  httpError: false,
  errors: {
    work_studies: {
      courses: [],
      computing_skills: [],
      formal_studies: [],
      language_studies: [],
      job_experiences: [],
      links: [],
    },
    work_objectives: [],
  },
  cvFiles: [],
  hasFileErrors: false,
  fileErrors: '',
  savedFile: false,
  deletedFile: false,
};
