import React, { useEffect } from 'react';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import * as enumsActions from 'frontend/actions/enums';
import { WorkObjectivesForm } from 'frontend/components/CurriculumForm/partials';

const usePreinitializelWorkObjective = (actions) => {
  const {
    getDriverLicenses,
    getOwnMobilities,
  } = actions;

  useEffect(() => {
    getDriverLicenses();
    getOwnMobilities();
  }, [
    getDriverLicenses,
    getOwnMobilities,
  ]);
};
const WorkObjective = ({
  actions,
  ...props
}) => {
  usePreinitializelWorkObjective(actions);

  return (
    <>
      <WorkObjectivesForm
        {...props}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  ownMobility: state.enums.curriculumOwnMobility,
  driverLicense: state.enums.curriculumDriverLicense,
});

function mapDispatchToProps(dispatch) {
  return ({
    actions: bindActionCreators({
      ...enumsActions,
    }, dispatch),
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(WorkObjective);
