import React from 'react';
import { Divider, Form, Segment } from 'semantic-ui-react';
import { Field } from 'redux-form';
import { RenderSelect } from 'frontend/components/Form';

const Experience = (props) => {
  const {
    professionalDevAreas,
    errors,
    onErrors,
  } = props;
  return (
    <Segment raised color="blue">
      <Divider horizontal>Experiencia</Divider>
      <Form.Group widths="equal">
        <Field
          component={RenderSelect}
          error={onErrors(errors['profile.job_experiences'])}
          name="profile.job_experience_ids"
          fluid
          multiple
          options={professionalDevAreas}
          content="Podés seleccionar hasta 3 opciones"
          label="Experiencia laboral (?)"
          placeholder="Experiencia laboral"
        />
        <Field
          component={RenderSelect}
          error={onErrors(errors['profile.job_expectations'])}
          name="profile.job_expectation_ids"
          fluid
          multiple
          content="Podés seleccionar hasta 3 opciones"
          options={professionalDevAreas}
          label="Empleo buscado (?)"
          placeholder="Empleo buscado"
        />
      </Form.Group>
    </Segment>
  );
};

export default Experience;
