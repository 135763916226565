export const GENDER_GET_REQUEST = 'gender_get_request';
export const GENDER_GET_SUCCESS = 'gender_get_success';
export const GENDER_GET_FAILURE = 'gender_get_failure';

export const INTEGRATOR_GENDER_GET_REQUEST = 'integrator_gender_get_request';
export const INTEGRATOR_GENDER_GET_SUCCESS = 'integrator_gender_get_success';
export const INTEGRATOR_GENDER_GET_FAILURE = 'integrator_gender_get_failure';

export const COUNTRY_GET_REQUEST = 'country_get_request';
export const COUNTRY_GET_SUCCESS = 'country_get_success';
export const COUNTRY_GET_FAILURE = 'country_get_failure';

export const DOCUMENT_TYPE_GET_REQUEST = 'document_type_get_request';
export const DOCUMENT_TYPE_GET_SUCCESS = 'document_type_get_success';
export const DOCUMENT_TYPE_GET_FAILURE = 'document_type_get_failure';

export const PROVINCE_GET_REQUEST = 'province_get_request';
export const PROVINCE_GET_SUCCESS = 'province_get_success';
export const PROVINCE_GET_FAILURE = 'province_get_failure';

export const MARITAL_STATUS_GET_REQUEST = 'marital_status_get_request';
export const MARITAL_STATUS_GET_SUCCESS = 'marital_status_get_success';
export const MARITAL_STATUS_GET_FAILURE = 'marital_status_get_failure';

export const ACHIEVED_STUDY_GET_REQUEST = 'achieved_study_get_request';
export const ACHIEVED_STUDY_GET_SUCCESS = 'achieved_study_get_success';
export const ACHIEVED_STUDY_GET_FAILURE = 'achieved_study_get_failure';

export const CAREER_GET_REQUEST = 'career_get_request';
export const CAREER_GET_SUCCESS = 'career_get_success';
export const CAREER_GET_FAILURE = 'career_get_failure';

export const GEM_PROVINCE_GET_REQUEST = 'gem_province_get_request';
export const GEM_PROVINCE_GET_SUCCESS = 'gem_province_get_success';
export const GEM_PROVINCE_GET_FAILURE = 'gem_province_get_failure';

export const PARTY_GET_REQUEST = 'party_get_request';
export const PARTY_GET_SUCCESS = 'party_get_success';
export const PARTY_GET_FAILURE = 'party_get_failure';

export const CITY_GET_REQUEST = 'city_get_request';
export const CITY_GET_SUCCESS = 'city_get_success';
export const CITY_GET_FAILURE = 'city_get_failure';

export const PROFESSIONAL_DEV_AREA_GET_REQUEST = 'professional_dev_area_get_request';
export const PROFESSIONAL_DEV_AREA_GET_SUCCESS = 'professional_dev_area_get_success';
export const PROFESSIONAL_DEV_AREA_GET_FAILURE = 'professional_dev_area_get_failure';

export const JOB_CATEGORIES_GET_REQUEST = 'job_categories_get_request';
export const JOB_CATEGORIES_GET_SUCCESS = 'job_categories_get_success';
export const JOB_CATEGORIES_GET_FAILURE = 'job_categories_get_failure';

export const INSTITUTIONS_GET_REQUEST = 'intitutions_get_request';
export const INSTITUTIONS_GET_SUCCESS = 'intitutions_get_success';
export const INSTITUTIONS_GET_FAILURE = 'intitutions_get_failure';

export const DEGREES_GET_REQUEST = 'degrees_get_request';
export const DEGREES_GET_SUCCESS = 'degrees_get_success';
export const DEGREES_GET_FAILURE = 'degrees_get_failure';

export const REPRESENTATIVES_GET_REQUEST = 'representatives_get_request';
export const REPRESENTATIVES_GET_SUCCESS = 'representatives_get_success';
export const REPRESENTATIVES_GET_FAILURE = 'representatives_get_failure';
