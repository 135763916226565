import {
  LOGIN_APPLICANT_FAILURE,
  LOGIN_APPLICANT_SUCCESS,
  LOGIN_APPLICANT_REQUEST,

  GET_LOGGED_USER_REQUEST,
  GET_LOGGED_USER_SUCCESS,
  GET_LOGGED_USER_FAILURE,

  ON_CAPTCHA_UPDATE,
  ON_DISMISS_MESSAGE,
} from 'frontend/constants/login-applicant';

import initialState from 'frontend/state/login-applicant';

export default (state = initialState, action) => {
  switch (action.type) {
    case ON_DISMISS_MESSAGE: {
      const {
        message,
      } = action.payload;
      return {
        ...state,
        [message]: false,
      };
    }
    case LOGIN_APPLICANT_REQUEST: {
      return {
        ...state,
        isFetching: true,
        hasErrors: false,
      };
    }
    case LOGIN_APPLICANT_SUCCESS: {
      const {
        loggedUser,
      } = action.payload;

      return {
        ...state,
        loggedUser,
        isFetching: false,
        askedForLogin: true,
        success: true,
        errors: '',
      };
    }
    case LOGIN_APPLICANT_FAILURE: {
      const {
        code,
        errors,
      } = action.payload;
      let message;
      if (code === 500) {
        message = 'Ocurrió un error en el servidor, intente más tarde';
      } else {
        message = errors;
      }
      return {
        ...state,
        failNumber: state.failNumber + 1,
        errors: message,
        hasErrors: true,
        isFetching: false,
      };
    }
    case GET_LOGGED_USER_REQUEST: {
      return {
        ...state,
        isFetching: true,
        hasErrors: false,
      };
    }
    case GET_LOGGED_USER_SUCCESS: {
      const {
        loggedUser,
      } = action.payload;

      return {
        ...state,
        loggedUser,
        isFetching: false,
        askedForLogin: true,
        success: true,
        errors: '',
      };
    }
    case GET_LOGGED_USER_FAILURE: {
      return {
        ...state,
        isFetching: false,
        askedForLogin: true,
        errors: '',
      };
    }

    case ON_CAPTCHA_UPDATE: {
      const { value } = action.payload;
      return {
        ...state,
        captchaValid: value,
      };
    }
    default:
      return state;
  }
};
