// esto es para obtener los datos pre cargados cuando te registras a traves del sso
export const APPLICANT_GET_REQUEST = 'applicant-get-request';
export const APPLICANT_GET_SUCCESS = 'applicant-get-success';
export const APPLICANT_GET_FAILURE = 'applicant-get-failure';

// Si bien dice applicant_update_profile se refiere a la actualizacion de los
// datos al registrarte por el SSO. Habria que cambiarlo porque es confuso.
export const APPLICANT_UPDATE_PROFILE_REQUEST = 'applicant-update-profile-request';
export const APPLICANT_UPDATE_PROFILE_SUCCESS = 'applicant-update-profile-success';
export const APPLICANT_UPDATE_PROFILE_FAILURE = 'applicant-update-profile-failure';

export const LOGIN_APPLICANT_REQUEST = 'login-applicant-request';
export const LOGIN_APPLICANT_SUCCESS = 'login-applicant-success';
export const LOGIN_APPLICANT_FAILURE = 'login-applicant-failure';

export const REGISTER_APPLICANT_REQUEST = 'register-applicant-request';
export const REGISTER_APPLICANT_SUCCESS = 'register-applicant-success';
export const REGISTER_APPLICANT_FAILURE = 'register-applicant-failure';

export const ON_DISMISS_MESSAGE = 'on-dismiss-message';

// Esta son las verdaderas constantes para el get y edit profile
export const APPLICANT_PROFILE_GET_REQUEST = 'applicant-profile-get-request';
export const APPLICANT_PROFILE_GET_SUCCESS = 'applicant-profile-get-success';
export const APPLICANT_PROFILE_GET_FAILURE = 'applicant-profile-get-failure';

export const PROFILE_UPDATE_REQUEST = 'profile-update-request';
export const PROFILE_UPDATE_SUCCESS = 'profile-update-success';
export const PROFILE_UPDATE_FAILURE = 'profile-update-failure';
