import React from 'react';
import { Field } from 'redux-form';
import {
  RenderInput,
  RenderSelect,
  RenderCheckbox,
  RenderTextArea,
} from 'frontend/components/Form';
import {
  Grid,
  Divider,
  Form,
  Button,
  Icon,
  Header,
} from 'semantic-ui-react';
import { remove, get } from 'helpers/reduxFormArray';

const Course = (props) => {
  const {
    keyName,
    index,
    fields,
    professionalDevAreas,
    courseDurationUnits,
    removeError,
    name,
    errors,
    onErrors,
  } = props;

  const actualCourse = get(fields, index);
  const actualYear = parseInt((new Date()).getFullYear(), 10);

  if (actualCourse._destroy) {
    return (
      <>
      </>
    );
  }

  return (
    <>
      <Field component={RenderInput} type="hidden" name={`${keyName}.id`} />
      <Form.Group>
        <Field
          component={RenderSelect}
          error={onErrors(errors.professional_development_area)}
          name={`${keyName}.professional_development_area_id`}
          fluid
          label="Rubro"
          width={6}
          placeholder="Rubro"
          required
          options={professionalDevAreas}
        />
        <Field
          component={RenderInput}
          error={onErrors(errors.year)}
          name={`${keyName}.year`}
          label="Año"
          type="number"
          width={6}
          min={actualYear - 60}
          max={actualYear + 2}
          placeholder="Año"
          required
        />
        <Field
          component={RenderCheckbox}
          error={onErrors(errors.certified)}
          name={`${keyName}.certified`}
          width={4}
          label="¿Posee certificado?"
        />
      </Form.Group>
      <Field
        component={RenderTextArea}
        error={onErrors(errors.course_description)}
        name={`${keyName}.course_description`}
        fluid
        required
        label="Breve descripción del curso"
        placeholder="Breve descripción del curso"
      />
      <Form.Group widths="equal">
        <Field
          component={RenderInput}
          error={onErrors(errors.duration_value)}
          name={`${keyName}.duration_value`}
          label="Duracion"
          type="number"
          placeholder="duracion"
          min="1"
        />
        <Field
          component={RenderSelect}
          error={onErrors(errors.duration_unit)}
          name={`${keyName}.duration_unit`}
          fluid
          label="Unidad"
          placeholder="Unidad"
          required={!!actualCourse.duration_value}
          options={courseDurationUnits}
        />
      </Form.Group>
      <Button type="button" basic color="red" onClick={remove(fields, index, removeError, name)}>
        Eliminar curso
      </Button>
      <Divider section />
    </>
  );
};

export default (errors, onErrors) => ({
  fields,
  ...props
}) => (
  <>
    <Grid>
      <Grid.Row>
        <Grid.Column width={11}>
          <Header as="h3" textAlign="left">
            <Icon name="graduation cap" />
            Cursos
          </Header>
        </Grid.Column>
        <Grid.Column width={5}>
          <Button
            type="button"
            size="tiny"
            basic
            fluid
            color="teal"
            onClick={() => fields.push({ completion: 0 })}
          >
            <Icon name="plus" />
            Agregar curso
          </Button>
        </Grid.Column>
      </Grid.Row>
    </Grid>
    <Divider />
    <>
      {fields.map((job, index) => (
        <Course
          key={index.toString()}
          keyName={job}
          name="courses"
          index={index}
          fields={fields}
          errors={errors[index] || {}}
          onErrors={onErrors}
          {...props}
        />
      ))}
    </>
  </>
);
