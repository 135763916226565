import React, { useEffect, useState } from 'react';
import * as jobOffersActions from 'frontend/actions/public-job-offers';
import * as enumsActions from 'frontend/actions/enums';
import { connect } from 'react-redux';
import { Icon } from 'semantic-ui-react';
import { bindActionCreators } from 'redux';
import JobOfferForm from 'frontend/components/PublicJobOffers/JobOffer';
import axios from 'helpers/axios';
import { Toast } from 'frontend/components/Form';

const disband = (fetchData, id, showSuccess, showError, serverError) => () => {
  showSuccess(false);
  showError(false);
  serverError(false);
  axios({
    url: `/api/v1/public_job_offers/${id}/disband`,
    method: 'post',
    data: { id },
  }).then((resp) => {
    fetchData();
    showSuccess(true, resp.data.message);
  }).catch((err) => {
    if (err.response.status === 500) serverError(true);
    else showError(true, err.response.data.message);
  });
};

const apply = (fetchData, id, showSuccess, showError, serverError) => () => {
  showSuccess(false);
  showError(false);
  serverError(false);
  axios({
    url: `/api/v1/public_job_offers/${id}/apply`,
    method: 'post',
    data: { id },
  }).then((resp) => {
    fetchData();
    showSuccess(true, resp.data.message);
  }).catch((err) => {
    if (err.response.status === 500) serverError(true);
    else if (err.response.status === 303) {
      setTimeout(() => { window.location = err.response.data.redirect; }, 1500);
    } else showError(true, err.response.data.message);
  });
};

const JobOffer = (props) => {
  const {
    actions,
    jobOffer,
    currentUser,
    contractTypes,
    match: {
      params: {
        id,
      },
    },
  } = props;

  useEffect(() => {
    actions.getPublicJobOffer(id);
    actions.getContractTypes();
  }, [actions, id]);

  const [success, setSuccess] = useState(false);
  const [successMessage, setSuccessMessage] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [error, setError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(false);

  const showSuccess = (active, message = '') => {
    setSuccess(active);
    setSuccessMessage(message);
  };

  const showError = (active, message = '') => {
    setError(active);
    setErrorMessage(message);
  };

  return (
    <>
      {
        serverError
        && (
          <Toast
            negative
            onDismiss={() => setServerError(false)}
            icon={<Icon color="red" name="x" />}
            header="Ocurrió un error en el sistema, intenta mas tarde."
          />
        )
      }
      {
        success
        && (
          <Toast
            success
            onDismiss={() => setSuccess(false)}
            icon={<Icon color="green" name="check" />}
            header={successMessage}
          />
        )
      }
      {
        error
        && (
          <Toast
            negative
            onDismiss={() => setError(false)}
            icon={<Icon color="red" name="x" />}
            header={errorMessage}
          />
        )
      }
      <JobOfferForm
        user={currentUser}
        jobOffer={jobOffer}
        contractTypes={contractTypes}
        id={id}
        apply={apply(
          () => actions.getPublicJobOffer(id),
          id,
          showSuccess,
          showError,
          setServerError,
        )}
        disband={disband(
          () => actions.getPublicJobOffer(id),
          id,
          showSuccess,
          showError,
          setServerError,
        )}
      />
    </>
  );
};

const mapStateToProps = (state) => ({
  jobOffer: state.publicJobOffers.public_job_offer,
  contractTypes: state.enums.jobOfferContractTypes,
});

function mapDispatchToProps(dispatch) {
  return ({
    actions: bindActionCreators({
      ...jobOffersActions,
      ...enumsActions,
    }, dispatch),
  });
}

export default connect(mapStateToProps, mapDispatchToProps)(JobOffer);
