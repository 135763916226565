import React from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import { bindActionCreators } from 'redux';
import { Toast } from 'frontend/components/Form';
import { Icon } from 'semantic-ui-react';
import LoginForm from 'frontend/components/Login/LoginFormContainer';
import * as applicantActions from 'frontend/actions/login-applicant';

class LoginApplicant extends React.Component {
  constructor(props) {
    super(props);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleRedirect = this.handleRedirect.bind(this);
    this.handleDismiss = this.handleDismiss.bind(this);
    this.state = {
      confirmed: false,
    };
  }

  componentDidMount() {
    const {
      location: {
        search,
      },
    } = this.props;

    if (search === '?confirmado=1') {
      this.setState({
        confirmed: true,
      });
    }
  }

  handleRedirect() {
    window.location = '/api/v1/sessions/applicants/sso';
  }

  handleSubmit(values) {
    const {
      actions: {
        loginApplicant,
      },
      loginApplicant: {
        failNumber,
        captchaValid,
      },
    } = this.props;
    if (failNumber < 3 && values.username && values.password) loginApplicant(values);
    if (failNumber > 2 && captchaValid) loginApplicant(values);
  }

  handleDismiss(message) {
    return () => {
      if (message === 'confirmed') {
        this.setState({
          confirmed: false,
        });
      } else {
        const {
          actions: {
            dismissMessage,
          },
        } = this.props;
        dismissMessage(message);
      }
    };
  }

  render() {
    const {
      loginApplicant: {
        hasErrors,
        errors,
        failNumber,
        captchaValid,
      },
    } = this.props;

    const { confirmed } = this.state;
    return (
      <>
        {
          confirmed
          && (
            <Toast
              positive
              icon={<Icon color="green" name="check" />}
              onDismiss={this.handleDismiss('confirmed')}
              content="Tu cuenta se ha confirmado correctamente"
            />
          )
        }
        {
          hasErrors
          && (
            <Toast
              negative
              icon={<Icon color="red" name="x" />}
              onDismiss={this.handleDismiss('hasErrors')}
              content={errors}
            />
          )
        }
        <LoginForm
          title="postulante"
          urlregister="/registrar-postulante"
          onSubmit={this.handleSubmit}
          failNumber={failNumber}
          captchaValid={captchaValid}
          applicant
          onRedirect={this.handleRedirect}
        />
      </>
    );
  }
}

const mapStateToProps = (state) => ({
  loginApplicant: state.loginApplicant,
  form: state.form,
});

function mapDispatchToProps(dispatch) {
  return ({
    actions: bindActionCreators({
      ...applicantActions,
    }, dispatch),
  });
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(LoginApplicant));
