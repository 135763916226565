export default {
  errors: '',
  failNumber: 0,
  captchaValid: false,
  hasErrors: false,
  isFetching: false,
  success: false,
  loggedUser: undefined,
  askedForLogin: false,
};
