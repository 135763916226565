import React from 'react';
import { Divider, Form, Segment } from 'semantic-ui-react';
import { Field } from 'redux-form';
import { RenderInput } from 'frontend/components/Form';

const Contact = (props) => {
  const {
    errors,
    onErrors,
  } = props;
  return (
    <Segment raised color="blue">
      <Divider horizontal>Contacto</Divider>
      <Form.Group widths="equal">
        <Field
          component={RenderInput}
          label="Correo electrónico"
          error={onErrors(errors.email)}
          name="email"
          placeholder="Correo electrónico"
          type="email"
          required
        />
        <Field
          component={RenderInput}
          error={onErrors(errors['profile.profile_phone[0].number'])}
          name="profile.profile_phone[0].number"
          fluid
          label="Teléfono de línea"
          placeholder="Teléfono de línea"
        />
        <Field
          component={RenderInput}
          error={onErrors(errors['profile.profile_phone[1].number'])}
          name="profile.profile_phone[1].number"
          fluid
          label="Celular"
          placeholder="Celular"
        />
      </Form.Group>
      <Divider horizontal />
    </Segment>
  );
};

export default Contact;
